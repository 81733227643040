import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Outlet,
} from "react-router-dom";
import './utils/api-interceptor';

function App() {

  return (
    <Outlet />
  );
}
export default App;
