import { FunctionComponent } from "react";
import "./FramComponentBg.css";

export type FrameComponentBgType = {
  className?: string;
};

const FrameComponentBg: FunctionComponent<FrameComponentBgType> = ({
  className = "",
}) => {
  return (
    <div className={`frame-wrapper1 ${className}`}>
      <div className="why-should-you-consider-fine-t-wrapper">
        <b className="why-should-you">Why should you consider fine-tuning?</b>
      </div>
    </div>
  );
};

export default FrameComponentBg;
