import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import "./Accordion.css";
import { CW } from "../../utils/constants";
interface AccordionCompProps {
  title: string;
}

const AccordionComp: React.FC<AccordionCompProps> = ({ title }) => {
    const [show, setShow] = useState(false)
  return (
    <div className="accordion-container">
      <Accordion>
        <Accordion.Header>
          <div className="accordion-header-content">
            <span>
              <b>Sample Quetions</b>
            </span>
            <span>
              Following are some sample questions to kick start your
              conversation
            </span>
          </div>
        </Accordion.Header>
        <Accordion.Body className="accordion-body-container">
         {title === CW ? <div className="accordion-body-content">
            <span>1. Who are the perpetrators in this case </span>
            <span>2. Who is the victim in this case </span>
            <span>3. What is the summary of case notes </span>
          </div>
          :
          <div className="accordion-body-content">
            <span>1. When will I receive my benefits?  </span>
            <span>2. When do I renew? </span>
            <span>3. Why is this document needed?  </span>

          </div> }
          <button
            className="view-more-button mt-4"
            onClick={() => setShow(!show)}
          >
            {!show ? "View More Sample Qs" : "View Less"}
          </button>
          {show && (
            <div className="accordion-nested mt-4">
              <Accordion className="mt-2">
                <Accordion.Header>
                  <div className="accordion-header-content">
                    <span className="mb-1">
                      <b>Case Questions</b>
                    </span>
                    <span>
                      We have also compiled some commonly asked questions about
                      a case with the help of SMEs and CCWIS data extract. You
                      can find these FAQs below: 
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="accordion-body-container">
                  {title === CW ? (
                    <div className="accordion-body-content">
                      <span className="px-4 py-2 ">
                        Who are the perpetrators in this case ?
                      </span>
                      <span className="px-4 py-2 border-top">
                        Who is the victim in this case ?
                      </span>
                      <span className="px-4 py-2 border-top">
                        what is the summary of case notes
                      </span>
                      <span className="px-4 py-2 border-top">
                        What is the reason for placing the child in foster care
                        ?
                      </span>
                      <span className="px-4 py-2 border-top">
                        What is the intake status?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        What is the length of stay in the foster care
                      </span>
                      <span className="px-4 py-2 border-top">
                        What is intake case history?
                      </span>
                      <span className="px-4 py-2 border-top">
                        What are the official determination assessments that
                        were done for the case?
                      </span>
                      <span className="px-4 py-2 border-top">
                        What is the current status of assessments in case
                        worker's notes ?
                      </span>
                      <span className="px-4 py-2 border-top">
                        What are the details of petitions in this case?
                      </span>
                    </div>
                  ) : (
                    <div className="accordion-body-content">
                      <span className="px-4 py-2">
                        When will I receive my benefits?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        When do I renew?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Why is this document needed?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Why is this needed for benefits?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Talk about why my application was denied.{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Verify my case status{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        I want to check the status of my case{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        What is my application status?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Check on my application{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Are my benefits open?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Are my food stamps open?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Is my cash open?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Is my medical assistance open?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        What is my SNAP payment?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        When will my SNAP Payment come?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Why did my benefits change?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        What happened to my benefits?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Why did my benefits close?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Why do I no longer have coverage?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Why is there a gap in my coverage?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        What forms do I need to submit?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Why aren’t my benefits open yet?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        What are my benefit details?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Find my case number{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Why did my benefits get shut off, I provided what was
                        needed?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Are my benefits going to close? I don’t understand what
                        you need.{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        I want to know information about my benefits for this
                        month and next month including amount, benefit type, and
                        renewal date{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Did I get approved?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        I want to know if you received my application?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        When will you change my benefits?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        When will you finish my change?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Morning ur say that u was going to send me a mailing for
                        me 4/27/2020 but I still didn't receive it yet{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Why is approval taking so long{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        What is my out of pocket share?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        What is my next renewal date?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        What is my renewal date for Medicare Savings?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        When is my interview?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        How much was I approved for?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        What is my TANF amount?{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Has my renewal been approved{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Iam trying to see why I was denied for food stamps{" "}
                      </span>
                      <span className="px-4 py-2 border-top">
                        Want to know if I got approved{" "}
                      </span>
                    </div>
                  )}
                </Accordion.Body>
              </Accordion>
              <Accordion className="mt-2">
                <Accordion.Header>
                  <div className="accordion-header-content">
                    <span className="mb-1">
                      <b>Policy Questions</b>
                    </span>
                    <span>We received below policy FAQs from SMEs:</span>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="accordion-body-container">
                 {title === CW ? <div className="accordion-body-content">
                    <span className="px-4 py-2 ">
                      What is the protocol for handling emergency situations or
                      immediate risks to a child's safety?
                    </span>
                    <span className="px-4 py-2 border-top">
                      How do we document our interactions with children and
                      their families in the system?
                    </span>
                    <span className="px-4 py-2 border-top">
                      What resources are available to us if we need help
                      handling a difficult case or situation?
                    </span>
                    <span className="px-4 py-2 border-top">
                      Can you provide examples of how to approach sensitive
                      conversations with children or their families?
                    </span>
                    <span className="px-4 py-2 border-top">
                      What are the key indicators we should look for when
                      assessing a child's wellbeing or a family's situation?
                    </span>
                    <span className="px-4 py-2 border-top">
                      How do we work with other agencies or community resources
                      to support a child's welfare?
                    </span>
                    <span className="px-4 py-2 border-top">
                      What are the policies and procedures for escalating a case
                      if necessary?
                    </span>
                    <span className="px-4 py-2 border-top">
                      How do we handle confidentiality and privacy concerns with
                      cases?
                    </span>
                    <span className="px-4 py-2 border-top">
                      Can you explain how the court process works in child
                      welfare cases and what our role is?
                    </span>
                    <span className="px-4 py-2 border-top">
                      What is the process for a child fatality?
                    </span>
                    <span className="px-4 py-2 border-top">
                      How do you document a child who went AWOL from the foster
                      home?
                    </span>
                    <span className="px-4 py-2 border-top">
                      What documentation is needed to have a child placed in a
                      residential facility?
                    </span>
                    <span className="px-4 py-2 border-top">
                      What is the ICPC process to have a child placed in another
                      state?
                    </span>
                    <span className="px-4 py-2 border-top">
                      What is the process when a child has Native American
                      process? ICWA?
                    </span>
                    <span className="px-4 py-2 border-top">
                      What is the process to become a respite provider?
                    </span>
                    <span className="px-4 py-2 border-top">
                      What is the process for relative and non-relative
                      providers to become foster parents?
                    </span>
                    <span className="px-4 py-2 border-top">
                      What is the timeframe for an investigation case?
                    </span>
                    <span className="px-4 py-2 border-top">
                      What is the process to transfer a case to adoptions?
                    </span>
                    <span className="px-4 py-2 border-top">
                      What is the process to determine if a young adult under 21
                      wants to re-enter foster care as a
                    </span>
                    <span className="px-4 py-2 border-top">
                      What is the case review system process?
                    </span>
                    {/* </span> */}
                  </div>
                  :
                  <div className="accordion-body-content">

<span className="px-4 py-2 ">How do I apply for benefits </span>
<span className="px-4 py-2 border-top">Where do I apply </span>
<span className="px-4 py-2 border-top">Learn about benefits </span>
<span className="px-4 py-2 border-top">I want to apply for benefits online </span>
<span className="px-4 py-2 border-top">Who do I call with questions for my application? </span>
<span className="px-4 py-2 border-top">How long is the application? </span>
<span className="px-4 py-2 border-top">What benefits can I apply for? </span>
<span className="px-4 py-2 border-top">What program can I apply to for assistance? </span>
<span className="px-4 py-2 border-top">What programs can I apply for? </span>
<span className="px-4 py-2 border-top">How long will it take for me to receive benefits? </span>
<span className="px-4 py-2 border-top">When will I receive my benefits? </span>
<span className="px-4 py-2 border-top">Which information do I need to provide to complete the application process? </span>
<span className="px-4 py-2 border-top">Which documents do I need to apply? </span>
<span className="px-4 py-2 border-top">What is the difference between Medicare and Medicaid? </span>
<span className="px-4 py-2 border-top">If I am a spouse, do I still need to become an Authorized Representative? ​ </span>
<span className="px-4 py-2 border-top">Who do I ask to remove my authorized representative? </span>
<span className="px-4 py-2 border-top">How do I remove my alternate payee?​ </span>
<span className="px-4 py-2 border-top">Can I let someone else complete my application and case activities?​ </span>
<span className="px-4 py-2 border-top">Can someone help me deal with my benefits? </span>
<span className="px-4 py-2 border-top">Can I have someone else use my EBT card? </span>
<span className="px-4 py-2 border-top">How do I know when to report change? </span>
<span className="px-4 py-2 border-top">What do I need to do when I have a new name? </span>
<span className="px-4 py-2 border-top">Can I get retroactive benefits if I forgot to report my pregnancy/birth? </span>
<span className="px-4 py-2 border-top">Can I get past pregnancy benefits? </span>
<span className="px-4 py-2 border-top">I forgot to tell you I was pregnant. </span>
<span className="px-4 py-2 border-top">When should I renew my benefits? </span>
<span className="px-4 py-2 border-top">What if I am missing required information? </span>
<span className="px-4 py-2 border-top">What if I don’t have something I need for benefits? </span>
<span className="px-4 py-2 border-top">Can I have mail sent somewhere other than my home? </span>
<span className="px-4 py-2 border-top">How do I make it so mail goes to a different place? </span>
<span className="px-4 py-2 border-top">What options do I have for missing ID? </span>

                  
                  </div> }
                </Accordion.Body>
              </Accordion>
            </div>
          )}
        </Accordion.Body>
      </Accordion>
    </div>
  );
};

export default AccordionComp;
