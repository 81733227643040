/* eslint-disable react/display-name */
import { useRef, useEffect, useState, useContext } from "react";
import {
  Form,
  Button,
  Modal,
  ButtonGroup,
  FormGroup,
  Col,
  Row,
  FormControl,
} from "react-bootstrap";
import { AiOutlineSend } from "react-icons/ai";
import { RiChatDeleteLine } from "react-icons/ri";
import ContentLoader from "../Loader/contentLoader";
import "./chatBox.css";
import UserContext from "../../context/UserContext";
import axios from "axios";
import { Chat } from "../../interfaces";
import "font-awesome/css/font-awesome.min.css";
import {
  AskMeAI,
  CS,
  CW,
  HIX,
  IE,
  ME,
  THUMBS_DOWN_ICON,
  THUMBS_UP_ICON,
} from "../../utils/constants";
import { fixXMLTags } from "../../utils/stringProcessing";
import stream from "../../utils/stream";
import { Accordion } from "react-bootstrap";

type ChatboxProps = {
  title: string;
  defaultText?: string;
  chatBackup?: any;
  setChatBackUp?: any;
  selectedOption?: boolean;
  setSelectedModelUi?: any;
};

const feedbackOptions = [
  { key: "option1", value: "Answer was incorrect" },
  { key: "option2", value: "Answer was not available" },
  { key: "option3", value: "Answer was incomplete" },
];

const Chatbox: React.FC<ChatboxProps> = ({
  title,
  defaultText,
  chatBackup,
  setChatBackUp,
  selectedOption,
  setSelectedModelUi,
}) => {
  const chatWrapper = useRef<null | HTMLPreElement>(null);
  const [showClearChatWarning, setShowClearChatWarning] =
    useState<boolean>(false);
  const [chatContents, setChatContents] = useState<Chat[]>([]);
  const [userInputPlaceHolderHeight, setUserInputPlacehodlderHeight] =
    useState<string>("10px");
  const userInputRef = useRef<HTMLTextAreaElement>(null);
  const [activeButton, setActiveButton] = useState<{ [key: number]: string }>(
    {}
  );
  const [feedback, setFeedback] = useState<string | null>(null);
  const [isActivityCreated, setIsActivityCreated] = useState<boolean>(false);
  const [activityId, setActivityId] = useState<string>("");
  const [showFeedbackOptions, setShowFeedBackOptions] =
    useState<boolean>(false);
  const [userInputNewLinecount, setUserInputNewLineCount] = useState(0);
  const [queries, setQueries] = useState<any[]>([]);
  const [sourceData, setSourceData] = useState<any[]>([]);
  const [showSourceModal, setShowSourceModal] = useState<boolean>(false);
  const [indexValue, setIndexValue] = useState<number>(0);
  const [modelSelectHover, setModelSelectHover] = useState(false);
  const [feedbackOptionsSelected, setFeedbackOptionsSelected] = useState<{
    [key: number]: string;
  }>({});
  const [feedBackSubmitted, setFeedBackSubmitted] = useState<{
    [key: number]: boolean;
  }>({});
  const [selectedModelChat, setSelectedModelChat] = useState<string>("gpt-4o");
  const userContext = useContext(UserContext) || {
    userInput: "",
    setUserInput: () => {},
    isLoading: false,
    setIsLoading: () => {},
  };

  const { userInput, setUserInput, isLoading, setIsLoading } = userContext;
  useEffect(() => {
    chatWrapper?.current?.scrollIntoView({ behavior: "smooth" });
  }, []);
  useEffect(() => {
    if (title === HIX) {
      setChatBackUp(chatContents);
      if (chatContents && chatContents.length === 0) {
        setChatContents(chatBackup);
      }
    }
  }, [chatContents]);

  useEffect(() => {
    !modelSelectHover &&
      chatWrapper?.current?.scrollTo({
        top: chatWrapper?.current?.scrollHeight,
        behavior: "smooth",
      });
  });

  const clearChat = () => {
    if (title === IE || title === CW || title === ME) {
      getResetChat()
        .then(() => setChatContents([]))
        .catch((error) => {
          console.error("Error in getResetChat:", error);
        });
    } else {
      setActivityId("");
      setChatContents([]);
      if (title === HIX) {
        setChatBackUp([]);
      }
      if (title === AskMeAI) {
        setSourceData([]);
      }
      setUserInput("");
      setIsLoading(false);
      setShowClearChatWarning(false);
      setFeedbackOptionsSelected({});
      setFeedBackSubmitted({});
      setActiveButton({});
    }
  };

  const cancelClearChat = () => {
    setShowClearChatWarning(false);
  };

  const userInputChange = (event: any) => {
    let pattern = /^[a-zA-Z0-9]*$/;
    if (pattern.test(userInput[userInput.length - 1])) {
      setUserInputNewLineCount(0);
    }
    setUserInput(event?.target?.value);
  };
  useEffect(() => {
    if (userInputRef.current) {
      userInputRef.current.style.height = "auto";
      userInputRef.current.style.height = `${userInputRef.current.scrollHeight}px`;
    }
  }, [userInput]);

  const submitPrompt = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsLoading(true);
    // setSourceData([]);
    setChatContents([...chatContents, { role: "user", content: userInput }]);
    let payload: any = {
      question: userInput,
    };
    if (title === HIX || title === AskMeAI) {
      payload = {
        stream: true,
        model:
          title === HIX
            ? selectedModelChat
            : "llama2:7b-medicaid-fedpolicy-60k-240404",
        temperature: 0,
        max_output_token: 1024,
        message_window: 5,
        activity_id: activityId ? activityId : undefined,
      };
    }
    if (title === AskMeAI) {
      payload = {
        ...payload,
        app_type: "askmemedicaid",
        include_sources: true,
      };
      if (selectedOption) {
        payload = {
          ...payload,
          rag: {
            collection_name: "CMS-Final-Rule-2024-240614",
          },
        };
      }
    }
    if (chatContents.length === 0) {
      payload = {
        ...payload,
        messages: [
          {
            role: "user",
            content: userInput,
          },
        ],
      };
      if (title === HIX) {
        if (
          selectedModelChat === "mistral:v0.3" ||
          selectedModelChat === "llama3:8b"
        ) {
          payload = {
            ...payload,
            metadata: [
              {
                plan_ids: [
                  "18581WA0140001-01",
                  "61836WA0050007-01",
                  "80473WA0540001-01",
                  "38498WA0320001-01",
                ],
              },
            ],
            app_type: "hix",
          };
        }
        if (selectedModelChat === "gpt-4o") {
          payload = {
            ...payload,
            metadata: [
              {
                plan_ids: [
                  "53732WA0790026-01",
                  "84481WA0060004-01",
                  "80473WA1000007-01",
                  "38498WA0320004-01",
                  "84481WA0060006-01",
                  "14057WA0060003-01",
                  "87718WA2170015-01",
                  "80473WA1000009-01",
                  "61836WA0050026-01",
                  "61836WA0050018-01",
                  "53732WA0790024-01",
                  "80473WA0990001-01",
                  "38498WA0320010-01",
                  "18581WA0140001-01",
                  "61836WA0050007-01",
                  "80473WA0540001-01",
                  "38498WA0320001-01",
                ],
              },
            ],
            app_type: "hix",
          };
        }
      }
    } else {
      if (title !== AskMeAI) {
        payload = {
          ...payload,
          messages: [
            ...chatContents.map((content) => {
              if (content.role === "assistant") {
                const { ...rest } = content;
                return rest;
              }
              return content;
            }),
            {
              role: "user",
              content: userInput,
            },
          ],
        };
      } else {
        payload = {
          ...payload,
          messages: [
            {
              role: "user",
              content: userInput,
            },
          ],
        };
      }
    }
    setUserInput("");
    if (title === HIX) {
      setChatBackUp(chatContents);
    }
    if (title === HIX || title === AskMeAI) {
      let payloadData = payload;
      // title === CS ? data : payload;
      handleStreamSubmit(payloadData);
    } else handlePromptSubmit(payload);
  };

  const handlePromptSubmit = async (payload: any) => {
    const ME_URL =
      "https://domain-ai-ma-be-dot-us-gcp-ame-con-e5556-npd-1.nn.r.appspot.com/chatbot";
    const CW_URL =
      "https://domain-ai-cw-be-dot-us-gcp-ame-con-e5556-npd-1.nn.r.appspot.com/chatbot";
    const IE_URL =
      "https://domain-ai-ie-snap-be-dot-us-gcp-ame-con-e5556-npd-1.nn.r.appspot.com/chatbot";
    const url = title === IE ? IE_URL : title === CW ? CW_URL : ME_URL;
    try {
      const response = await axios.post(url, payload);
      const responseData = response.data;
      setIsLoading(false);
      setChatContents((prevChatContents) => [
        ...prevChatContents,
        { role: "assistant", content: responseData?.answer },
      ]);
    } catch (error: any) {
      setIsLoading(false);
      console.error("Error:", error.response?.data || error.message);
      throw error;
    }
  };

  const handleStreamSubmit = async (payload: any) => {
    setChatContents([
      ...chatContents,
      { role: "user", content: userInput } as Chat,
      {
        role: "assistant",
        content: "",
        hadFeedbackOptions: showFeedbackOptions,
      } as Chat,
    ]);
    setUserInput("");
    setIsLoading(true);
    setShowFeedBackOptions(false);
    stream({
      requestBody: payload,
      streamCallback: (value: string) => {
        setChatContents((oldChatContents) => {
          const lastChatContent = oldChatContents[oldChatContents.length - 1];
          const updatedChatContent = {
            ...lastChatContent,
            content: fixXMLTags(`${lastChatContent.content}${value}`),
          };
          return [...oldChatContents.slice(0, -1), updatedChatContent];
        });
      },
      doneCallback: () => {
        setIsLoading(false);
        setChatContents((oldChatContents) => {
          const lastChatContent = oldChatContents[oldChatContents.length - 1];
          if (lastChatContent.role === "assistant") {
            const updatedChatContent = {
              ...lastChatContent,
              hadFeedbackOptions: true,
            };
            return [...oldChatContents.slice(0, -1), updatedChatContent];
          }
          return oldChatContents;
        });
        // setShowFeedBackOptions(true);
        // setShowToast(false);
      },
      title: title,
      // setShowToast: setShowToast,
      setIsLoading: setIsLoading,
      activityId: activityId,
      setActivityId: setActivityId,
      setSourceData: setSourceData,
    });
  };
  const onHover = () => {
    setModelSelectHover(true);
  };

  const onLeave = () => {
    setModelSelectHover(false);
  };
  const handleFeedbackChange = (e: any, value: string, index: any) => {
    setActiveButton({ ...activeButton, [index]: value });
    let updatedChatContents;
    if (value === "yes") {
      updatedChatContents = chatContents.map((message, i) => {
        if (i === index && message.role === "assistant") {
          return { ...message, is_helpful: true, comment: undefined };
        }
        return message;
      });
    } else {
      if (e?.target?.type !== "button") {
        setFeedbackOptionsSelected({
          ...feedbackOptionsSelected,
          [index]: e?.target?.value,
        });
        updatedChatContents = chatContents.map((message, i) => {
          if (i === index && message.role === "assistant") {
            return { ...message, is_helpful: false, comment: e.target.value };
          }
          return message;
        });
      }
    }
    if (updatedChatContents) {
      setChatContents(updatedChatContents);
    }
    let feedbackPayload = {
      messages: updatedChatContents?.map(
        ({ hadFeedbackOptions, ...message }) => message
      ),
      activity_id: activityId,
    };
    if (
      value === "yes" ||
      (value === "no" && e?.target?.type !== "button" && e?.target?.value)
    ) {
      handleFeedback(feedbackPayload, index);
    }
  };

  const handleFeedback = async (payload: any, index: number) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_HOST_PORT}/api/activity/update/`,
        payload,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        setFeedBackSubmitted({ ...feedBackSubmitted, [index]: true });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleModelSelectChange = (e: any) => {
    setSelectedModelChat(e.target.value);
    setSelectedModelUi(e.target.value);

    setChatContents([]);
    setChatBackUp([]);
  };
  const handleEnterAndNewLinecheck = (event: any) => {
    //Enter is pressed and there is userInput and Shiftkey is not pressed query is submitted
    if (userInputRef.current) {
      userInputRef.current.style.height = "auto";
      userInputRef.current.style.height = `${userInputRef.current.scrollHeight}px`;
    }
    if (event.keyCode === 13 && userInput && !event.shiftKey) {
      // title === AskMeAI ? submitChatPrompt(event) : submitPrompt(event);
      submitPrompt(event);
      setUserInputNewLineCount(0);
    }
    //Enter is pressed and userinput is empty and no shiftkey will do nothing
    if (event.keyCode === 13 && !userInput && !event.shiftKey) {
      event.preventDefault();
      setUserInputNewLineCount(0);
      return;
    }
    if (event.keyCode === 13 && event.shiftKey) {
      // Add new line on shift + enter
      if (userInputNewLinecount > 2) {
        event.preventDefault();
        return;
      } else {
        const previousUserInput = userInput + "\n";
        setUserInputNewLineCount(userInputNewLinecount + 1);
        setUserInput(previousUserInput);
      }
    }
  };
  const getResetChat = async () => {
    try {
      const Reset_ME_URL =
        "https://domain-ai-ma-be-dot-us-gcp-ame-con-e5556-npd-1.nn.r.appspot.com/refresh";
      const Rest_IE_URL =
        "https://domain-ai-ie-snap-be-dot-us-gcp-ame-con-e5556-npd-1.nn.r.appspot.com/refresh";
      const Reset_CW_URL =
        "https://domain-ai-cw-be-dot-us-gcp-ame-con-e5556-npd-1.nn.r.appspot.com/refresh";
      const url =
        title === IE ? Rest_IE_URL : title === CW ? Reset_CW_URL : Reset_ME_URL;
      const response = await axios.get(url);
      if (response?.status === 200) {
        setUserInput("");
        setIsLoading(false);
        setShowClearChatWarning(false);
        return Promise.resolve();
      }
    } catch (error) {
      console.error("Error in getResetChat:", error);
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    if (title === IE || title === CW || title === ME) {
      getResetChat();
    }
  }, []);

  const cancelSourcePopup = () => {
    setShowSourceModal(false);
  };

  const handleViewDetails = (index: any) => {
    const mappedIndex = Math.floor(index / 2);
    setIndexValue(mappedIndex);
    setShowSourceModal(true);
  };
  const sourceDataModal = () => {
    return (
      <div className="clear-chat-warning-container">
        <Modal
          show={showSourceModal}
          onHide={cancelSourcePopup}
          backdrop="static"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Source Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="delete-warning">
              {(sourceData?.[indexValue] || []).length > 0 ? (
                sourceData?.[indexValue]?.map((item: any, index: any) => {
                  return (
                    <div
                      className="accordion-container"
                      key={index}
                      style={{ width: "90%" }}
                    >
                      <Accordion>
                        <Accordion.Header>
                          {item?.page_content?.split(" ").slice(0, 9).join(" ")}
                          ...
                        </Accordion.Header>
                        <Accordion.Body className="accordion-body-container">
                          <p>Page Content: {item?.page_content}</p>
                          <p>Type: {item?.type}</p>
                          <p>Metadata:</p>
                          <ul>
                            <li>Page: {item?.metadata?.page}</li>
                            <li>Source: {item?.metadata?.source}</li>
                          </ul>
                        </Accordion.Body>
                      </Accordion>
                    </div>
                  );
                })
              ) : (
                <p>
                  This response was generated by the finetuned model that is
                  trained on CMS documentation.
                </p>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  return (
    <div>
      <div className="chatbox-container">
        <pre className="chat-wrapper" ref={chatWrapper}>
          <div className="assistant-chat individual-chatbox">
            <span className="fw-bold capitalize">Assistant:</span> {defaultText}
          </div>
          {window.location.href.includes("/hix") && (
            <div 
            onMouseEnter={onHover}
            onMouseLeave={onLeave}
            >
              <Form.Group className="select-model">
                <Form.Label className="model-div model-label">
                  Select a model
                </Form.Label>
                <Form.Select
                  className="model-div"
                  onChange={(e) => handleModelSelectChange(e)}
                >
                  <option value="gpt-4o">GPT 4o</option>
                  <option value="llama3:8b">LLama 3:8b</option>
                  <option value="mistral:v0.3">Mistral V3</option>
                </Form.Select>
              </Form.Group>
            </div>
          )}
          {modelSelectHover && (
            <div className="model-selection-note">
              <i className="fa fa-info-circle"></i> Gpt 4o has 17 Plan ids
              whereas Llama 3:8b and Mistral V-3 has 4 plan ids
            </div>
          )}
          {chatContents.map((chat: Chat, index: any) => (
            <div
              key={index}
              className={
                chat?.role === "user"
                  ? "user-chat individual-chatbox"
                  : "assistant-chat individual-chatbox"
              }
            >
              <span className="fw-bold capitalize">{`${chat?.role === "user" ? "User" : "Assistant"}: `}</span>{" "}
              {chat?.content}
              {chat?.role !== "user" && chat?.hadFeedbackOptions && (
                <FormGroup>
                  <Col sm={12}>
                    <ButtonGroup className="mt-2">
                      <Button
                        className={
                          activeButton[index] === "yes" ? "active" : ""
                        }
                        variant={
                          feedback === "up" ? "success" : "outline-success"
                        }
                        onClick={(e) => handleFeedbackChange(e, "yes", index)}
                        // disabled={feedBackSubmitted[index] && activeButton[index] === "no" ? true : false}
                      >
                        {THUMBS_UP_ICON}
                      </Button>
                      <Button
                        className={activeButton[index] === "no" ? "active" : ""}
                        variant={
                          feedback === "down" ? "danger" : "outline-danger"
                        }
                        onClick={(e) => handleFeedbackChange(e, "no", index)}
                        // disabled={activeButton[index] === "yes" && feedBackSubmitted[index] ? true : false}
                      >
                        {THUMBS_DOWN_ICON}
                      </Button>
                    </ButtonGroup>
                    {sourceData && title === AskMeAI && (
                      <ButtonGroup>
                        <button
                          className="view-details"
                          onClick={() => handleViewDetails(index)}
                        >
                          {" "}
                          View Details
                        </button>
                      </ButtonGroup>
                    )}
                  </Col>
                  {activeButton[index] === "no" && (
                    <Row>
                      <Col>
                        <Form.Group
                          className="user-input"
                          controlId="controlId.desigMethod"
                        >
                          <Form.Select
                            aria-label="Select Feeback Options"
                            className="instance-picker mt-2"
                            value={
                              feedbackOptionsSelected
                                ? feedbackOptionsSelected[index]
                                : "Answer was incorrect"
                            }
                            onChange={(e) =>
                              handleFeedbackChange(e, "no", index)
                            }
                            // disabled={feedBackSubmitted[index] ? true : false}
                          >
                            <option value="">Select an Option</option>
                            {feedbackOptions.map((method: any) => (
                              <option value={method.value}>
                                {method.value}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  {activeButton[index] && feedBackSubmitted[index] && (
                    <Row>
                      <p className="mt-2" style={{ fontSize: "12px" }}>
                        Feedback Submitted !
                      </p>
                    </Row>
                  )}
                </FormGroup>
              )}
            </div>
          ))}
        </pre>
        <Form>
          <Form.Group
            className="chat-user-input"
            controlId="exampleForm.userInput"
          >
            <Form.Control
              as="textarea"
              placeholder="Enter your prompt"
              className="user-prompt-input"
              ref={userInputRef}
              value={userInput}
              rows={1}
              onChange={(e) => userInputChange(e)}
              onKeyDown={(e) => handleEnterAndNewLinecheck(e)}
              draggable={false}
              style={{ resize: "none" }}
            />
            <div className="user-story-generator-submit-container">
              {isLoading ? (
                <Button
                  className="user-story-generator-submit primary-btn-style"
                  disabled={true}
                >
                  <ContentLoader />
                </Button>
              ) : (
                <Button
                  className="user-story-generator-submit primary-btn-style"
                  type="submit"
                  onClick={submitPrompt}
                  disabled={!userInput?.length}
                >
                  <AiOutlineSend />
                </Button>
              )}
              <Button
                className="clear-chat-btn"
                variant="danger"
                onClick={() => {
                  setShowClearChatWarning(true);
                }}
                disabled={!(userInput?.length || chatContents?.length)}
              >
                <RiChatDeleteLine size="1.25rem" />
              </Button>
            </div>
          </Form.Group>
        </Form>
      </div>
      {showClearChatWarning && (
        <div className="clear-chat-warning-container">
          <Modal
            show={showClearChatWarning}
            onHide={cancelClearChat}
            backdrop="static"
            keyboard={true}
          >
            <Modal.Header closeButton>
              <Modal.Title>Clear Chat?</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>
                This action will clear the chat history and create a new
                session. Are you sure you want to clear the chat?
              </p>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={clearChat}>
                Clear
              </Button>
              <Button variant="secondary" onClick={cancelClearChat}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      {showSourceModal && sourceDataModal()}
    </div>
  );
};

export default Chatbox;
