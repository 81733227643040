import { FunctionComponent } from "react";
import "./Cards.css";
import PerformanceImg from '../../../../assets/Frame 1272628631.png';

export type CardsType = {
  className?: string;
};

const Cards: FunctionComponent<CardsType> = ({ className = "" }) => {
  return (
    <div className={`frame-parent8 ${className}`}>
      <div className="frame-wrapper2">
        <div className="group-parent">
          <img className="group-icon" alt="" src={PerformanceImg} />
          <div className="performance">Performance</div>
        </div>
      </div>
      <div className="reduce-hallucinations-improved-container">
        <p className="reduce-hallucinations">Reduce Hallucinations</p>
        <p className="reduce-hallucinations">Improved Consistency</p>
        <p className="reduce-hallucinations">Control Information</p>
      </div>
    </div>
  );
};

export default Cards;
