import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { redirect } from 'react-router-dom';

export const authenticateUser = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST_PORT}/auth/authentication/sso-login`
    );
    const redirect_url = response.data?.redirect_url;

    if (redirect_url) {
        window.location.href = redirect_url
       return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const setAuthToken = (token: any) => {
  sessionStorage.setItem('jwtToken', token);
  const decoded = jwtDecode(token);
  const userName = decoded?.sub;
  const exp = decoded?.exp;
  sessionStorage.setItem('user', JSON.stringify(userName));
  sessionStorage.setItem('expiry', JSON.stringify(exp));
};

export const getAuthToken = () => {
  return sessionStorage.getItem('jwtToken');
};

export const removeAuthToken = () => {
  sessionStorage.removeItem('jwtToken');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('expiry');
};
