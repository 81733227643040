import {FC} from 'react'
import { Link } from 'react-router-dom';
import { CS } from '../../utils/constants';
interface Card {
    title : string;
    description : string;
    path: string;
}
const Cards: FC<Card>  = ({title, description, path}) => {
  return (
    <>
    <Link to={path} style={{textDecoration: 'none', color:'inherit'}} target={title === CS ? "_blank" : "_self"} 
    rel={title === CS ? "noopener noreferrer" : ""}>
      <div className="card-container">
        <b className="medicaid">{title}</b>
        <div className="powered-by-meta">{description}</div>
       
      </div>
      </Link>
    </>
  );
}

export default Cards