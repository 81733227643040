import React from 'react';
import { Chat } from '../interfaces';
import { SetStateFunction } from '../types';

export interface UserContextType { 
  showToast: boolean;
  setShowToast: React.Dispatch<boolean>;
  showErrMsg: string;
  setShowErrMsg: React.Dispatch<string>;
  chatContents: Chat[];
  setChatContents: SetStateFunction<Chat[]>;
  userInput: string;
  setUserInput: (userInput: string) => void; 
  isLoading: boolean;
  setIsLoading: SetStateFunction<boolean>; 
  setOutputData: SetStateFunction<string>;
  outputData: string; 
}

const UserContext = React.createContext<UserContextType | null>(null);

export default UserContext;
