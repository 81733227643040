import { FunctionComponent } from "react";
import "./homepage.css";
import image5 from '../../assets/image-5@2x.png';
import image4 from '../../assets/image-4@2x.png';
import screenshotImg from '../../assets/screenshot-20240425-at-236-1@2x.png';
import data from '../../data.json';
import Cards from "../../components/cards";
import FrameComponent from "../FrameComponent";
import { RiShareBoxLine } from "react-icons/ri";

const HomepageOption: FunctionComponent = () => {
  return (
    <div className="homepage-option-6">
      <div className="frame-parent">
        <div className="deloittes-fine-tuning-exhibit-parent">
          <div className="deloittes-fine-tuning">
            GovConnect.ai
          </div>
          <div className="transform-our-tailored-container">
            <p className="transform-our-tailored">
              Transform your business with domain specific models tailored to your needs! 
            </p>
            {/* <p className="transform-our-tailored">
              Streamline Communication and Boost Efficiency with Ease
            </p> */}
          </div>
          <div className="button-parent">
            <div className="button">
              <div className="text1">Know More</div>
            </div>
            <div className="button1">
              <div className="text1">Sign In</div>
            </div>
          </div>
        </div>
        <div className="image-5-parent">
          <img className="image-5-icon" alt="" src={image5} />
          <div className="frame-child" />
          <div className="frame-group">
            <div className="tailored-chat-bots-wrapper">
              <div className="tailored-chat-bots">Domain Specific Models</div>
            </div>
            <div className="frame-container">
              <div className="card-parent">
                {data.data?.models?.map((model:any) => {
                  return (
                    <Cards title={model.title} description={model.desc} path={model.path}/>
                  )
                })}
              </div> 
            </div>
          </div>
        </div>
        <FrameComponent />
        {/* <div className="frame-div">
          <div className="frame-wrapper">
            <div className="tailored-chat-bots-wrapper">
              <div className="our-top-features">Our Top Features</div>
            </div>
          </div>
          <div className="frame-parent1">
            <div className="image-4-parent">
              <img className="image-4-icon" alt="" src={image4} />
              <div className="top-features-parent">
                <div className="top-features">Top Features</div>
                <div className="lorem-ipsum-dolor-sit-amet-co-wrapper">
                  <div className="lorem-ipsum-dolor">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </div>
                </div>
              </div>
            </div>
            <div className="screenshot-2024-04-25-at-236-parent">
              <img
                className="screenshot-2024-04-25-at-236"
                alt=""
                src={screenshotImg}
              />
              <div className="top-features-group">
                <div className="top-features">Top Features</div>
                <div className="lorem-ipsum-dolor-sit-amet-co-wrapper">
                  <div className="lorem-ipsum-dolor1">
                    Lorem ipsum dolor sit amet, consectetur.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="frame-parent2">
          <div className="get-in-touch-parent">
            <div className="tailored-chat-bots">Get In Touch</div>
            <div className="lorem-ipsum-dolor2">Build your own fine-tuned model today.</div>
          </div>
          <div className="deloittegpsdeloittecom-parent">
            <div className="deloittegpsdeloittecom">
              deloittegps@deloitte.com
            </div>
            <RiShareBoxLine className="external-link-icon"/>
          </div>
        </div>
      </div>
      <div className="copywright-2024-all-rights-wrapper">
        <div className="copywright-2024">
          Copyright © 2024 - All rights reserved
        </div>
      </div>
    </div>
  );
};

export default HomepageOption;
