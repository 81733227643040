import React, { useState } from 'react';
import UserContext from './UserContext';
import { Chat } from '../interfaces';

const UserContextProvider = ({ children }: any) => {
 
  const [showToast, setShowToast] = useState<boolean>(false);
  const [showErrMsg, setShowErrMsg] = useState<string>('');
  const [chatContents, setChatContents] = useState<Chat[]>([]);
  const [userInput, setUserInput] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
   const [outputData, setOutputData] = useState<string>('');
 return (
    <UserContext.Provider
      value={{      
        showToast,
        setShowToast,
        showErrMsg,
        setShowErrMsg,
        chatContents,
        setChatContents,
        userInput,
        setUserInput,
        isLoading,
        setIsLoading,      
        setOutputData,
        outputData,      
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
