import { FunctionComponent } from "react";
import FrameComponentBg from "./FrameComponentBg";
import CardContainer from "./CardContainer";
import "./FrameComponent.css";

export type FrameComponentType = {
  className?: string;
};

const FrameComponent: FunctionComponent<FrameComponentType> = ({
  className = "",
}) => {
  return (
    <div className={`frame-parent13 ${className}`}>
      <FrameComponentBg />
      <CardContainer />
    </div>
  );
};

export default FrameComponent;
