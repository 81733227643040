import React, { FC } from "react";
import { Col,Accordion, FormGroup,FormLabel } from "react-bootstrap";
interface Filters{
    // map(arg0: any): unknown;
    filterOptions?:any
}
const HixFilters:FC<Filters>=({
    filterOptions}
)=>{
    return( <>
     
     <FormGroup>
         <input type="checkbox"></input>
            <FormLabel className="Filter-Label">
                {filterOptions}
            </FormLabel>
           
         </FormGroup>
       
       
       

    </>)
}
export default HixFilters