import React, { FC } from "react";
import {
  Container,
  Row,
  Col,
  Accordion,
  FormGroup,
  FormLabel,
  Button,
  FormSelect,
  Card,
  Badge,
} from "react-bootstrap";
import './HixCards.css'
import { FaStar } from "react-icons/fa";
import { bronze, bronze_caps, catastrophic, catastrophic_caps, gold, gold_caps, platinum, platinum_caps, silver, silver_caps } from "../../utils/constants";
interface Card {
  issuerName?:string,
  metalTier?:string,
  monthlyPremium?:string,
  planName?:string,
  networkType?:string,
  primaryCareVisitCost?:string,
  genericDrugs?:string,
  providerInNetworkCount?:string,
  planDeductible?:string,
  medicalDeductible?:string,
  drugDeductible?:string,
  estimatedCost?:string,
  starRating?:number
}
const HixCards: FC<Card> = ({
  issuerName,
  metalTier,
  monthlyPremium,
  planName,
  networkType,
  primaryCareVisitCost,
  genericDrugs,
  providerInNetworkCount,
  planDeductible,
  medicalDeductible,
  drugDeductible,
  estimatedCost,
  starRating,
}
) => {
  const [rating, setRating] = React.useState(0);
 
  

  return (
    <>
      <Row>
        {" "}
        <div className="Plan-div-description">
        <Col  sm={4}>
          {" "}
          <Card className="text-center  Plan-Star-rating">
            <Card.Body>
              {" "}
              <Card.Text className="star-rating">{issuerName}</Card.Text>
              <div className="star-rating">
                {" "}
                {
                    [...Array(5).fill(0)].map((_, i) => {
                        let x=starRating?starRating:0
                        if (i < x) {
                          return <FaStar key={i + 3} className="text-warning" />;
                        } else {
                          return <FaStar key={i + 3} className="text-muted" />;
                        }
                      })
              }
                {" "}
              </div>{" "}
              {(metalTier=== silver||metalTier=== silver_caps)&&(
              <Badge bg="" style={{backgroundColor: '#C0C0C0'}}>{metalTier}</Badge>)
              }
              {(metalTier=== gold ||metalTier=== gold_caps)&&(
              <Badge bg="" style={{backgroundColor: '#FFD700'}}>{metalTier}</Badge>)
              }
              {(metalTier=== bronze ||metalTier=== bronze_caps)&&(
              <Badge bg="" style={{backgroundColor: '#CD7F32'}}>{metalTier}</Badge>)
              }
              {metalTier=== catastrophic ||metalTier=== catastrophic_caps &&(
              <Badge bg="" style={{backgroundColor: '#CD7F32'}}>{metalTier}</Badge>)
              }
               {metalTier===platinum || metalTier===platinum_caps&&(
              <Badge bg="" style={{backgroundColor: '#E5E4E2'}}>{metalTier}</Badge>)
              }
              <div className="star-rating">You Pay</div>{" "}
              <p>
                {"$"} <span style={{ fontSize: "32px" }}>{monthlyPremium}</span>
                .00/year
              </p>{" "}
              <Button variant="primary">Choose Plan</Button>{" "}
            </Card.Body>{" "}
          </Card>{" "}
        </Col>{" "}
        <Col sm={8} className="Plan-Right-Col">
          {" "}
          <Row>
            {" "}
            <Col sm={6}>
              {" "}
              <div>
                {planName} {networkType}{" "}
              </div>{" "}
            </Col>{" "}
            <Col sm={6}>
              {" "}
              <FormGroup className="Card-Display">
                {" "}
                <FormLabel style={{ marginRight: "15px", marginTop: "10px" }}>
                  {" "}
                  Compare{" "}
                </FormLabel>{" "}
                <input type="checkbox"></input>{" "}
              </FormGroup>{" "}
            </Col>{" "}
          </Row>{" "}
          <hr style={{ marginTop: "10px" }}></hr>{" "}
          <Row>
            {" "}
            <Col sm={4}>Primary Care Visits {primaryCareVisitCost}</Col>{" "}
            <Col sm={4}>Generic Prescription Drugs {genericDrugs}</Col>{" "}
            <Col sm={4}>
              Your Preffered Providers {providerInNetworkCount} provider in
              network
            </Col>{" "}
          </Row>{" "}
          <Row>
            {" "}
            <Col>
              {" "}
              <p>Yearly Deductible</p>{" "}
              {planDeductible && drugDeductible&& medicalDeductible &&(
              <p>
                {medicalDeductible} /year(Medical) |{" "}
                {drugDeductible} /year(Drug)
              </p>)}
              {" "}
                
            </Col>{" "}
          </Row>{" "}
          <Row>
            <Col>
              <div>
                Estimated Cost
              </div>
              <p>${estimatedCost}/year</p>
              <p>Based on your health care needs</p>

            </Col>
          </Row>
        </Col>
        </div>
        {" "}
      </Row>
    </>
  );
};
export default HixCards;
