import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import UserContextProvider from './context/UserContextProvider';
import { PrivateRoute } from './privateRoute';
import { Route, RouterProvider, Routes, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import Homepage from './pages/homepage';
import ChatScreen from './pages/chatScreen';
import HixComparison from './pages/HixComparison';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRoute>
        <UserContextProvider>
          <App />
        </UserContextProvider>
      </PrivateRoute>
    ),
    children: [
      {
        path: '/',
        element: <Homepage />,
      },
      {
        path: '/chatflow/:id',
        element: <ChatScreen />,
      },
      {
        path: '/hix',
        element: <HixComparison />,
      },
    ],
  },
  {
    path: '*',
    element: (
      <Routes>
        <Route path="*" element={<ErrorPage error={404} />} />
        <Route path="/403" element={<ErrorPage error={403} />} />
      </Routes>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
