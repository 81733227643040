//Feedback Component Contants
export const ENTER_FEEDBACK_PLACEHOLDER = 'Please enter feedback...';
export const FEEDBACK_THANK_YOU_MSG = 'Thank you for your feedback!';
export const WAS_THIS_HELPFUL = 'Was this helpful?';
export const FEEDBACK_ERROR =
  'Sorry we encountered some issue in sending your feedback!';
export const SUBMIT = 'Submit';
export const THUMBS_UP_ICON = '👍';
export const THUMBS_DOWN_ICON = '👎';

export const IE = 'Integrated Eligibility';
export const HIX = 'Health Insurance Exchange';
export const CW = 'Child Welfare';
export const AskMeAI = 'Askme.Medicaid.AI'
export const CS = 'Child Support';

//For Hix
export const HixJson1={
  "totalPlansCount": 34,
  "monthlySavings": 294.72,
  "filterOptions": {
      "metalTiers": [
          "Platinum",
          "Gold",
          "Silver",
          "Silver CSR",
          "Bronze",
          "HSA Eligible"
      ],
      "insuranceCompanies": [
          "Molina Health Care",
          "SHARP Health Plan",
          "Anthem Blue Cross",
          "Blue Shield",
          "Kaiser",
          "Health Net"
      ],
      "networkType": [
          "HMO",
          "PPO"
      ]
  },
  "selectedFilters": {
      "metalTiers": [
          "Silver",
          "Silver CSR"
      ],
      "insuranceCompanies": []
  },
  "defaultSort": "Lowest Estimated Total Cost",
  "plans": [
    {
      "planNumber": "18581WA0140001-01",
      "issuerHiosId": 75362,
      "issuerName": "CHPW",
      "planName": "Community Health Plan of Washington Cascade Select Gold",
      "networkType": "EPO",
      "isCSR": false,
      "isHSA": false,
      "qualityRating": 4,
      "metalTier": "Gold",
      "planDeductible": {
        "deductibleType": "Integrated",
        "medicalDeductible": "$600 /year",
        "drugDeductible": "Integrated"
      },
      "genericDrugs": "$10 Copay",
      "primaryCareVisitCost": "$15 Copay",
      "providerInNetworkCount": 0,
      "providers": [],
      "monthlyPremium": 383.88,
      "yearlyEstimatedExpense": null
    },
    {
      "planNumber": "61836WA0050007-01",
      "issuerHiosId": 75365,
      "issuerName": "Coordinated Care Corporation",
      "planName": "Ambetter Balanced Care 4",
      "networkType": "HMO",
      "isCSR": false,
      "isHSA": false,
      "qualityRating": 4,
      "metalTier": "Silver",
      "planDeductible": {
        "deductibleType": "Integrated",
        "medicalDeductible": "$8150 /year",
        "drugDeductible": "Integrated"
      },
      "genericDrugs": "$3 Copay",
      "primaryCareVisitCost": "$30 Copay",
      "providerInNetworkCount": 0,
      "providers": [],
      "monthlyPremium": 366.06,
      "yearlyEstimatedExpense": null
    },
    {
      "planNumber": "80473WA0540001-01",
      "issuerHiosId": 75384,
      "issuerName": "Kaiser WA",
      "planName": "Basics Plus Catastrophic",
      "networkType": "HMO",
      "isCSR": false,
      "isHSA": false,
      "qualityRating": 5,
      "metalTier": "Catastrophic",
      "planDeductible": {
        "deductibleType": "Integrated",
        "medicalDeductible": "$9450 /year",
        "drugDeductible": "Integrated"
      },
      "genericDrugs": "You pay up to the deductible",
      "primaryCareVisitCost": "You pay up to the deductible",
      "providerInNetworkCount": 0,
      "providers": [],
      "monthlyPremium": 320.66,
      "yearlyEstimatedExpense": null
    },
    {
      "planNumber": "38498WA0320001-01",
      "issuerHiosId": 75394,
      "issuerName": "LifeWise WA",
      "planName": "LifeWise Essential Gold",
      "networkType": "EPO",
      "isCSR": false,
      "isHSA": false,
      "qualityRating": 4,
      "metalTier": "Gold",
      "planDeductible": {
        "deductibleType": "Integrated",
        "medicalDeductible": "$1000 /year",
        "drugDeductible": "Integrated"
      },
      "genericDrugs": "$10 Copay",
      "primaryCareVisitCost": "$30 Copay",
      "providerInNetworkCount": 0,
      "providers": [],
      "monthlyPremium": 583,
      "yearlyEstimatedExpense": null
    }
  ],
  "leadPlansCount": 0,
  "leadIssuerName": [],
  "message": {
      "responseCode": "200",
      "status": "success",
      "description": "success"
  }
}
export const HixJson2={
	"status": "OK",
	"errorCode": "",
	"errorDescription": "",
  "filterOptions": {
    "metalTiers": [
        "Platinum",
        "Gold",
        "Silver",
        "Silver CSR",
        "Bronze",
        "HSA Eligible"
    ],
    "insuranceCompanies": [
        "Molina Health Care",
        "SHARP Health Plan",
        "Anthem Blue Cross",
        "Blue Shield",
        "Kaiser",
        "Health Net"
    ],
    "networkType": [
        "HMO",
        "PPO"
    ]
},
"selectedFilters": {
    "metalTiers": [
        "Silver",
        "Silver CSR"
    ],
    "insuranceCompanies": []
},
"defaultSort": "Lowest Estimated Total Cost",
	"plans": [
		{
			"planName": "Community Health Plan of Washington Cascade Select Gold",
			"cmsPlanID": "18581WA0140001-01",
			"ehbFactor": 99.79,
			"coverageLevel": "GOLD",
			"marketType": "IND",
			"hpfPlanId": 75362,
			"networkName": "CHPW Cascade Affiliates Network",
			"networkType": "EPO",
			"issuer": {
				"issuerName": "CHPW",
				"feinNumber": "911729710",
				"logoURL": "https://uat.wahpf.org/issuer-logo/911729710.png"
			},
			"totalPlanPremium": 383.88,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_0.0",
				"text": "COMMENT_15"
			},
			"isCofaPlanCd": null,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 383.88,
							"capMemberPremium": 383.88,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / $450 copay after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $10"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "6100.0"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "600.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $15"
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$6,100 Individual / $12,200 Family"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$600 Individual / $1,200 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": 56
		},
		{
			"planName": "Ambetter Balanced Care 4",
			"cmsPlanID": "61836WA0050007-01",
			"ehbFactor": 99.81,
			"coverageLevel": "SILVER",
			"marketType": "IND",
			"hpfPlanId": 75365,
			"networkName": "CCCWA Exchange",
			"networkType": "HMO",
			"issuer": {
				"issuerName": "Coordinated Care Corporation",
				"feinNumber": "391821211",
				"logoURL": "https://uat.wahpf.org/issuer-logo/391821211.png"
			},
			"totalPlanPremium": 366.06,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_4.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": 56,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 366.06,
							"capMemberPremium": 366.06,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / Covered at no cost to you after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $3"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "8150.0"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "8150.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $30"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$8,150 Individual / $16,300 Family"
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$8,150 Individual / $16,300 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": null
		},
		{
			"planName": "Basics Plus Catastrophic",
			"cmsPlanID": "80473WA0540001-01",
			"ehbFactor": 99.21,
			"coverageLevel": "CATASTROPHIC",
			"marketType": "IND",
			"hpfPlanId": 75384,
			"networkName": "Core",
			"networkType": "HMO",
			"issuer": {
				"issuerName": "Kaiser WA",
				"feinNumber": "910511770",
				"logoURL": "https://uat.wahpf.org/issuer-logo/910511770.png"
			},
			"totalPlanPremium": 320.66,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_5.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": null,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 320.66,
							"capMemberPremium": 320.66,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / Covered at no cost to you after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay up to the deductible / Covered at no cost to you after you meet your deductible"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "9450.0"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "9450.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay up to the deductible / Covered at no cost to you after you meet your deductible. See Additional Benefits for an exception."
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$9,450 Individual / $18,900 Family"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$9,450 Individual / $18,900 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": null
		},
		{
			"planName": "LifeWise Essential Gold",
			"cmsPlanID": "38498WA0320001-01",
			"ehbFactor": 99.89,
			"coverageLevel": "GOLD",
			"marketType": "IND",
			"hpfPlanId": 75394,
			"networkName": "LifeWise Primary",
			"networkType": "EPO",
			"issuer": {
				"issuerName": "LifeWise WA",
				"feinNumber": "911950223",
				"logoURL": "https://uat.wahpf.org/issuer-logo/911950223.png"
			},
			"totalPlanPremium": 583,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_4.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": null,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 583,
							"capMemberPremium": 583,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / 30% of the cost of care after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $10"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "7300.0"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "1000.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $30"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$7,300 Individual / $14,600 Family"
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$1,000 Individual / $2,000 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": null
		}
	]
}
export const Hix_Json3={
	"status": "OK",
	"errorCode": "",
	"errorDescription": "",
  "filterOptions": {
    "metalTiers": [
        "Platinum",
        "Gold",
        "Silver",
        "Silver CSR",
        "Bronze",
        "HSA Eligible"
    ],
    "insuranceCompanies": [
        "Molina Health Care",
        "SHARP Health Plan",
        "Anthem Blue Cross",
        "Blue Shield",
        "Kaiser",
        "Health Net"
    ],
    "networkType": [
        "HMO",
        "PPO"
    ]
},
"selectedFilters": {
    "metalTiers": [
        "Silver",
        "Silver CSR"
    ],
    "insuranceCompanies": []
},
"defaultSort": "Lowest Estimated Total Cost",
	"plans": [
		{
			"planName": "Ambetter Balanced Care 4",
			"cmsPlanID": "61836WA0050007-01",
			"ehbFactor": 99.81,
			"coverageLevel": "SILVER",
			"marketType": "IND",
			"hpfPlanId": 75365,
			"networkName": "CCCWA Exchange",
			"networkType": "HMO",
			"issuer": {
				"issuerName": "Coordinated Care Corporation",
				"feinNumber": "391821211",
				"logoURL": "https://uat.wahpf.org/issuer-logo/391821211.png"
			},
			"totalPlanPremium": 366.06,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_4.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": 56,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 366.06,
							"capMemberPremium": 366.06,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / Covered at no cost to you after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $3"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "8150.0"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "8150.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $30"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$8,150 Individual / $16,300 Family"
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$8,150 Individual / $16,300 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": null
		},
		{
			"planName": "Community Health Plan of Washington Cascade Select Gold",
			"cmsPlanID": "18581WA0140001-01",
			"ehbFactor": 99.79,
			"coverageLevel": "GOLD",
			"marketType": "IND",
			"hpfPlanId": 75362,
			"networkName": "CHPW Cascade Affiliates Network",
			"networkType": "EPO",
			"issuer": {
				"issuerName": "CHPW",
				"feinNumber": "911729710",
				"logoURL": "https://uat.wahpf.org/issuer-logo/911729710.png"
			},
			"totalPlanPremium": 383.88,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_0.0",
				"text": "COMMENT_15"
			},
			"isCofaPlanCd": null,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 383.88,
							"capMemberPremium": 383.88,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / $450 copay after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $10"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "6100.0"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "600.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $15"
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$6,100 Individual / $12,200 Family"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$600 Individual / $1,200 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": 56
		},
		{
			"planName": "Basics Plus Catastrophic",
			"cmsPlanID": "80473WA0540001-01",
			"ehbFactor": 99.21,
			"coverageLevel": "CATASTROPHIC",
			"marketType": "IND",
			"hpfPlanId": 75384,
			"networkName": "Core",
			"networkType": "HMO",
			"issuer": {
				"issuerName": "Kaiser WA",
				"feinNumber": "910511770",
				"logoURL": "https://uat.wahpf.org/issuer-logo/910511770.png"
			},
			"totalPlanPremium": 320.66,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_5.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": null,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 320.66,
							"capMemberPremium": 320.66,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / Covered at no cost to you after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay up to the deductible / Covered at no cost to you after you meet your deductible"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "9450.0"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "9450.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay up to the deductible / Covered at no cost to you after you meet your deductible. See Additional Benefits for an exception."
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$9,450 Individual / $18,900 Family"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$9,450 Individual / $18,900 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": null
		},
		{
			"planName": "LifeWise Essential Gold",
			"cmsPlanID": "38498WA0320001-01",
			"ehbFactor": 99.89,
			"coverageLevel": "GOLD",
			"marketType": "IND",
			"hpfPlanId": 75394,
			"networkName": "LifeWise Primary",
			"networkType": "EPO",
			"issuer": {
				"issuerName": "LifeWise WA",
				"feinNumber": "911950223",
				"logoURL": "https://uat.wahpf.org/issuer-logo/911950223.png"
			},
			"totalPlanPremium": 583,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_4.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": null,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 583,
							"capMemberPremium": 583,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / 30% of the cost of care after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $10"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "7300.0"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "1000.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $30"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$7,300 Individual / $14,600 Family"
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$1,000 Individual / $2,000 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": null
		},
		{
			"planName": "BridgeSpan Cascade Bronze",
			"cmsPlanID": "53732WA0790026-01",
			"ehbFactor": 99.8,
			"coverageLevel": "BRONZE",
			"marketType": "IND",
			"hpfPlanId": 75361,
			"networkName": "RealValue",
			"networkType": "EPO",
			"issuer": {
				"issuerName": "BridgeSpan",
				"feinNumber": "870388069",
				"logoURL": "https://uat.wahpf.org/issuer-logo/870388069.png"
			},
			"totalPlanPremium": 442.52,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_0.0",
				"text": "COMMENT_7"
			},
			"isCofaPlanCd": null,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 442.52,
							"capMemberPremium": 442.52,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / 40% of the cost of care after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $32"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "9200.0"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "6000.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $50. See Additional Benefits for an exception."
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$9,200 Individual / $18,400 Family"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$6,000 Individual / $12,000 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": 56
		},
		{
			"planName": "Constant Care Silver 1",
			"cmsPlanID": "84481WA0060004-01",
			"ehbFactor": 99.84,
			"coverageLevel": "SILVER",
			"marketType": "IND",
			"hpfPlanId": 75322,
			"networkName": "Molina Marketplace",
			"networkType": "HMO",
			"issuer": {
				"issuerName": "Molina",
				"feinNumber": "911284790",
				"logoURL": "https://uat.wahpf.org/issuer-logo/911284790.png"
			},
			"totalPlanPremium": 420.37,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_4.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": 56,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 420.37,
							"capMemberPremium": 420.37,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay 35% of the cost of care"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $28"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "7725.0"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "0.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $30"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$7,725 Individual / $15,450 Family"
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "$0 Medical individual / $0 Family"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "Non-integrated"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "$900 Prescription drug individual / $1,800 Family"
				}
			],
			"isCascadePlanCd": null
		},
		{
			"planName": "Kaiser Permanente Cascade Bronze",
			"cmsPlanID": "80473WA1000007-01",
			"ehbFactor": 99.22,
			"coverageLevel": "BRONZE",
			"marketType": "IND",
			"hpfPlanId": 75391,
			"networkName": "Core",
			"networkType": "HMO",
			"issuer": {
				"issuerName": "Kaiser WA",
				"feinNumber": "910511770",
				"logoURL": "https://uat.wahpf.org/issuer-logo/910511770.png"
			},
			"totalPlanPremium": 408.93,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_5.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": null,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 408.93,
							"capMemberPremium": 408.93,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / 40% of the cost of care after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $32"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "9200.0"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "6000.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $50. See Additional Benefits for an exception."
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$9,200 Individual / $18,400 Family"
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$6,000 Individual / $12,000 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": 56
		},
		{
			"planName": "LifeWise Essential Silver Low Deductible",
			"cmsPlanID": "38498WA0320004-01",
			"ehbFactor": 99.88,
			"coverageLevel": "SILVER",
			"marketType": "IND",
			"hpfPlanId": 75396,
			"networkName": "LifeWise Primary",
			"networkType": "EPO",
			"issuer": {
				"issuerName": "LifeWise WA",
				"feinNumber": "911950223",
				"logoURL": "https://uat.wahpf.org/issuer-logo/911950223.png"
			},
			"totalPlanPremium": 534.51,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_4.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": 56,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 534.51,
							"capMemberPremium": 534.51,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / 30% of the cost of care after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $20"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "8340.0"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "2945.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $30"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$8,340 Individual / $16,680 Family"
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$2,945 Individual / $5,890 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": null
		},
		{
			"planName": "Molina Cascade Silver",
			"cmsPlanID": "84481WA0060006-01",
			"ehbFactor": 99.85,
			"coverageLevel": "SILVER",
			"marketType": "IND",
			"hpfPlanId": 75324,
			"networkName": "Molina Marketplace",
			"networkType": "HMO",
			"issuer": {
				"issuerName": "Molina",
				"feinNumber": "911284790",
				"logoURL": "https://uat.wahpf.org/issuer-logo/911284790.png"
			},
			"totalPlanPremium": 441.58,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_4.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": 56,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 441.58,
							"capMemberPremium": 441.58,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / $800 copay after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $25"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "9200.0"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "2500.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $30. See Additional Benefits for an exception."
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$9,200 Individual / $18,400 Family"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$2,500 Individual / $5,000 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": 56
		},
		{
			"planName": "Navigator Bronze 7000",
			"cmsPlanID": "14057WA0060003-01",
			"ehbFactor": 99.81,
			"coverageLevel": "BRONZE",
			"marketType": "IND",
			"hpfPlanId": 75327,
			"networkName": "Navigator",
			"networkType": "PPO",
			"issuer": {
				"issuerName": "PacificSource",
				"feinNumber": "930245545",
				"logoURL": "https://uat.wahpf.org/issuer-logo/930245545.png"
			},
			"totalPlanPremium": 385.54,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_4.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": null,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 385.54,
							"capMemberPremium": 385.54,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / 40% of the cost of care after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay up to the deductible / 40% of the cost of care after you meet your deductible"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "8700.0"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "7000.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $35"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$8,700 Individual / $17,400 Family"
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$7,000 Individual / $14,000 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": null
		},
		{
			"planName": "Regence Cascade Bronze",
			"cmsPlanID": "87718WA2170015-01",
			"ehbFactor": 99.6,
			"coverageLevel": "BRONZE",
			"marketType": "IND",
			"hpfPlanId": 75350,
			"networkName": "Individual and Family Network",
			"networkType": "EPO",
			"issuer": {
				"issuerName": "Regence Blue Shield",
				"feinNumber": "910282080",
				"logoURL": "https://uat.wahpf.org/issuer-logo/910282080.png"
			},
			"totalPlanPremium": 396.82,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_4.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": null,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 396.82,
							"capMemberPremium": 396.82,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / 40% of the cost of care after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $32"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "9200.0"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "6000.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $50. See Additional Benefits for an exception."
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$9,200 Individual / $18,400 Family"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$6,000 Individual / $12,000 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": 56
		},
		{
			"planName": "Virtual Plus Bronze",
			"cmsPlanID": "80473WA1000009-01",
			"ehbFactor": 99.28,
			"coverageLevel": "BRONZE",
			"marketType": "IND",
			"hpfPlanId": 75393,
			"networkName": "Connect",
			"networkType": "HMO",
			"issuer": {
				"issuerName": "Kaiser WA",
				"feinNumber": "910511770",
				"logoURL": "https://uat.wahpf.org/issuer-logo/910511770.png"
			},
			"totalPlanPremium": 385.72,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_5.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": null,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 385.72,
							"capMemberPremium": 385.72,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / Covered at no cost to you after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $35"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "9450.0"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "9450.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $34.46. See Additional Benefits for an exception."
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$9,450 Individual / $18,900 Family"
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$9,450 Individual / $18,900 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": null
		},
		{
			"planName": "Ambetter Cascade Bronze",
			"cmsPlanID": "61836WA0050026-01",
			"ehbFactor": 99.81,
			"coverageLevel": "BRONZE",
			"marketType": "IND",
			"hpfPlanId": 75370,
			"networkName": "CCCWA Exchange",
			"networkType": "HMO",
			"issuer": {
				"issuerName": "Coordinated Care Corporation",
				"feinNumber": "391821211",
				"logoURL": "https://uat.wahpf.org/issuer-logo/391821211.png"
			},
			"totalPlanPremium": 281.39,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_4.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": null,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 281.39,
							"capMemberPremium": 281.39,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / 40% of the cost of care after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $32"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "9200.0"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "6000.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $50. See Additional Benefits for an exception."
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$9,200 Individual / $18,400 Family"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$6,000 Individual / $12,000 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": 56
		},
		{
			"planName": "Ambetter Cascade Gold",
			"cmsPlanID": "61836WA0050018-01",
			"ehbFactor": 99.81,
			"coverageLevel": "GOLD",
			"marketType": "IND",
			"hpfPlanId": 75368,
			"networkName": "CCCWA Exchange",
			"networkType": "HMO",
			"issuer": {
				"issuerName": "Coordinated Care Corporation",
				"feinNumber": "391821211",
				"logoURL": "https://uat.wahpf.org/issuer-logo/391821211.png"
			},
			"totalPlanPremium": 401.77,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_4.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": null,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 401.77,
							"capMemberPremium": 401.77,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / $450 copay after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $10"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "6100.0"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "600.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $15"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$6,100 Individual / $12,200 Family"
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$600 Individual / $1,200 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": 56
		},
		{
			"planName": "BridgeSpan Cascade Gold",
			"cmsPlanID": "53732WA0790024-01",
			"ehbFactor": 99.8,
			"coverageLevel": "GOLD",
			"marketType": "IND",
			"hpfPlanId": 75359,
			"networkName": "RealValue",
			"networkType": "EPO",
			"issuer": {
				"issuerName": "BridgeSpan",
				"feinNumber": "870388069",
				"logoURL": "https://uat.wahpf.org/issuer-logo/870388069.png"
			},
			"totalPlanPremium": 706.6,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_0.0",
				"text": "COMMENT_7"
			},
			"isCofaPlanCd": null,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 706.6,
							"capMemberPremium": 706.6,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / $450 copay after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $10"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "6100.0"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "600.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $15"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$6,100 Individual / $12,200 Family"
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$600 Individual / $1,200 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": 56
		},
		{
			"planName": "Flex Bronze",
			"cmsPlanID": "80473WA0990001-01",
			"ehbFactor": 99.26,
			"coverageLevel": "BRONZE",
			"marketType": "IND",
			"hpfPlanId": 75386,
			"networkName": "Core",
			"networkType": "HMO",
			"issuer": {
				"issuerName": "Kaiser WA",
				"feinNumber": "910511770",
				"logoURL": "https://uat.wahpf.org/issuer-logo/910511770.png"
			},
			"totalPlanPremium": 403.9,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_5.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": null,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 403.9,
							"capMemberPremium": 403.9,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / 20% of the cost of care after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $25"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "9200.0"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "6000.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay up to the deductible / 20% of the cost of care after you meet your deductible. See Additional Benefits for an exception."
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$9,200 Individual / $18,400 Family"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$6,000 Individual / $12,000 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": null
		},
		{
			"planName": "LifeWise Cascade Gold",
			"cmsPlanID": "38498WA0320010-01",
			"ehbFactor": 99.9,
			"coverageLevel": "GOLD",
			"marketType": "IND",
			"hpfPlanId": 75398,
			"networkName": "LifeWise Primary",
			"networkType": "EPO",
			"issuer": {
				"issuerName": "LifeWise WA",
				"feinNumber": "911950223",
				"logoURL": "https://uat.wahpf.org/issuer-logo/911950223.png"
			},
			"totalPlanPremium": 670.47,
			"qualityRating": {
				"code": "QUALITY_RATING_1",
				"value": "RATING_4.0",
				"text": "COMMENT_1"
			},
			"isCofaPlanCd": null,
			"families": [
				{
					"familyId": 1,
					"members": [
						{
							"memberId": 1,
							"relationship": "PA",
							"dob": "1991-01-01",
							"tobacco": "NO",
							"covStrtDt": "2024-02-01",
							"memberPremium": 670.47,
							"capMemberPremium": 670.47,
							"age": 33
						}
					]
				}
			],
			"benefits": [
				{
					"description": "Emergency Services - Facility",
					"code": "HTH_BNT_TYP_69",
					"value": "You pay up to the deductible / $450 copay after you meet your deductible"
				},
				{
					"description": "Generic Drugs",
					"code": "HTH_BNT_TYP_66",
					"value": "You pay $10"
				},
				{
					"description": "Out of Pocket Limit",
					"code": "HTH_BNT_TYP_5",
					"value": "6100.0"
				},
				{
					"description": "Health Savings Account (HSA) Eligible",
					"code": "HTH_BNT_TYP_4",
					"value": "No"
				},
				{
					"description": "Out of Pocket Costs - Annual deductible",
					"code": "HTH_BNT_TYP_195",
					"value": "600.0"
				},
				{
					"description": "Primary Care Visit to Treat an Injury or Illness",
					"code": "HTH_BNT_TYP_8",
					"value": "You pay $15"
				},
				{
					"description": "Medical Deductible",
					"code": "HTH_BNT_TYP_214",
					"value": "Integrated"
				},
				{
					"description": "Maximum Out of Pocket - Total",
					"code": "HTH_BNT_TYP_193",
					"value": "$6,100 Individual / $12,200 Family"
				},
				{
					"description": "Combined Medical and Drug Deductible",
					"code": "HTH_BNT_TYP_215",
					"value": "$600 Individual / $1,200 Family"
				},
				{
					"description": "Drug Deductible",
					"code": "HTH_BNT_TYP_51",
					"value": "Integrated"
				}
			],
			"isCascadePlanCd": 56
		}
	]
}
export const silver="Silver"
export const silver_caps="SILVER"

export const gold="gold"
export const gold_caps="GOLD"

export const bronze="Bronze HDHP"
export const bronze_caps="BRONZE"


export const catastrophic="Catastrophic"
export const catastrophic_caps="CATASTROPHIC"


export const platinum="Platinum"
export const platinum_caps="PLATINUM"



export const ME = 'Medicaid Eligibility';
