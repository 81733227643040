import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { authenticateUser, setAuthToken } from './auth';

export const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
    const [authenticated, setAuthenticated] = useState(
        !!sessionStorage.getItem('jwtToken')
      );
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('jwtToken') ? true : false;
      if (!token) {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (!token) {
          authenticateUser();
        } else {
          setAuthToken(token);
          setAuthenticated(true);
          navigate('/');
        }
      } else setAuthenticated(true);

  }, []);

  return authenticated ? (
    <>{children}</>
  ) : (
    null
  );
};
