import { AskMeAI, HIX } from "./constants";

interface Chat {
  role: string;
  content: string;
}

type SetStateFunction<T> = React.Dispatch<React.SetStateAction<T>>;
const stream = ({
  requestBody,
  streamCallback,
  doneCallback,
  setShowToast,
  setIsLoading,
  setShowErrMsg,
  signal,
  title,
  activityId,
  setActivityId,
  setSourceData,
}: {
  requestBody: any;
  streamCallback: Function;
  doneCallback?: Function;
  setShowToast?: any;
  setIsLoading: any;
  setShowErrMsg?: SetStateFunction<string>;
  signal?: AbortSignal;
  title?: string;
  activityId?:any;
  setActivityId?:any,
  setSourceData?:any
}) => {
  const token = sessionStorage.getItem('jwtToken');

  let streamingUrl = '';
  if (title === HIX) {
    streamingUrl = `${process.env.REACT_APP_API_HOST_PORT}/api/hix/chat`;
  } else {
    streamingUrl = `${process.env.REACT_APP_API_HOST_PORT}/api/askme-medicaid-ai/v2/chat`;
  }
  
  fetch(streamingUrl, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(requestBody),
    signal: signal,
  })
    .then((response) => {
      if (response.status === 401 || response.status === 403) {
        window.location.href = '/403';
        return;
      }
      if (response.ok) {
        const reader = response?.body?.getReader();
        const source = (response?.headers as any)?.get('X-Sources');
        if (source) {
          const parsedSource = JSON.parse(source);
          setSourceData((prevSourceData: any) => [...prevSourceData, parsedSource]);
      } else { 
          setSourceData((prevSourceData: any) => [...prevSourceData, []]);
      }   
          setActivityId((response?.headers as any)?.get('x-activityid'));
        const read = () => {
          reader
            ?.read()
            .then(({ done, value }) => {
              if (done) {
                if (doneCallback) {
                  doneCallback();
                }
                return;
              }

              const decoder = new TextDecoder();
              streamCallback(decoder.decode(value));
              read();
            })
            .catch((error) => {
              if (error.name === 'AbortError') {
                console.error('Fetch aborted');
              } else {
                console.error('Fetch error:', error);
              }
            });
        };

        read();
       
      } else {
        // setShowToast(true);
        // setShowErrMsg &&
        //   setShowErrMsg('There is a internal server error. Please try again.');
        setIsLoading(false);
      }
    })
    .catch((err) => {
      // setShowToast(true);
      setIsLoading(false);
      console.error('err==>', err);
    });
};

export default stream;
