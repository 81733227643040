type ChatFlowType = {
  [key: string]: {
    id: number;
    title: string;
    subTitle: string;
    description: string;
    chatFlowId: string;
    defaultText?: string;
  };
};
export const CHATFLOWS: ChatFlowType = {
  "llama2-7b": {
    id: 1,
    title: "Medicaid - Llama2 7B",
    subTitle: "Chat model",
    description: "No Fine-tuning",
    chatFlowId: "918b0a08-7d1c-4835-a03a-c584d171021e",
    defaultText: "",
  },
  "IE-Snap": {
    id: 2,
    title: "Integrated Eligibility",
    subTitle: "IE Snap Chat model",
    description:
      "IE Snap Chatbot to interact with the user and provide information about the policies.",
    chatFlowId: "72bf3ce6-81db-4f8d-9317-9b710f1ae33d",
    defaultText:
      "I am your Integrated Eligibility Case Assistant. How can I assist you today for your policy/case queries?",
  },
  "HIX-Policy": {
    id: 3,
    title: "Health Insurance Exchange",
    subTitle: "HIX Policy Chat model",
    description: "Chatbot to compare and interact with HIX policies",
    chatFlowId: "b6c9e850-998f-4a12-b7f0-a283fa003b94",
    defaultText:
      "I am your HIX Bot. How can I assist you today for your queries related to Health Policy Plans?",
  },
  "Askme-Medicaid": {
    id: 4,
    title: "Askme.Medicaid.AI",
    subTitle:
      "This tool enables users to ask questions based on two recent CMS final rules, 2439 Medicaid Program; Medicaid and Children's Health Insurance Program (CHIP) Managed Care Access, Finance, and Quality and 2442 Ensuring Access to Medicaid Services, to support efficient navigation and initial understanding of these rules.",
    description:
      "Please note, you should verify answers with a human-in-the-loop approach. Voting buttons are provided to indicate thumbs up, the response was helpful, and thumbs down, the response was not helpful",
    chatFlowId: "b6c9e850-998f-4a12-b7f0-a283fa003b94",
    defaultText:
      "I am your Askme Bot. How can I assist you today for your queries related to Medicaid?",
  },
  "CW-Case": {
    id: 5,
    title: "Child Welfare",
    subTitle: "CW Case Chat model",
    description:
      "Chatbot to interact with the user and provide information about the policies.",
    chatFlowId: "b6c9e850-998f-4a12-b7f0-a283fa003b94",
    defaultText:
      "I am your Child Welfare Case Assistant. How can I assist you today for your policy/case queries?",
  },
  "CS-Case": {
    id: 6,
    title: "Child Support",
    subTitle: "Child Support Case Chat model",
    description:
      "Chatbot to interact with the user and provide information about the policies.",
    chatFlowId: "b6c9e850-998f-4a12-b7f0-a283fa003b94",
    defaultText: "",
  },
  "Medicaid-Eligibility": {
    id: 7,
    title: "Medicaid Eligibility",
    subTitle: "Medicaid Eligibility Chat model",
    description:
      "Chatbot to interact with the user and provide information about the policies.",
    chatFlowId: "b6c9e850-998f-4a12-b7f0-a283fa003b94",
    defaultText:
      "I am your Medicaid Eligibility Case Assistant. How can I assist you today for your policy/case queries?",
  },
};
