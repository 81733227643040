import React, { useState } from "react";
import "./Banner.css";
import infoIcon from "../../assets/info.png";
import { Col, Modal, Row } from "react-bootstrap";
import { CW } from "../../utils/constants";
interface BannerProps {
  title: string;
}

const IE_Text = `This model is trained on a very specific data set that includes questions and answer pairs generated from Integrated Eligibility SNAP policy docs shared with us by SMEs. These policy docs are from
“Bridges Administrative Manual, State of Michigan, Department of Health & Human” and “Code of Colorado Regulations, Secretary of State, State of Colorado, Department of Human Services”.`;

const Default_Text = `This model is trained on a very specific data set that includes questions and answer pairs generated from CA CW policy docs shared with us by SMEs. These policy docs are from “State of California – Health and Human Service Agency – Department of Social Services”.`;


const IE_Case_ID = [
  {
    caseID: "Case ID",
    individualID: "Individual ID",
  },
  {
    caseID: "122256385",
    individualID: "1024126370",
  },
  {
    caseID: "122256088",
    individualID: "1024124621",
  },
  {
    caseID: "122256088",
    individualID: "1024131023",
  },
  {
    caseID: "122256091",
    individualID: "1024124625",
  },
  {
    caseID: "122256092",
    individualID: "1024124670",
  },
  {
    caseID: "122256093",
    individualID: "1024124720",
  },
]
const Banner: React.FC<BannerProps> = ({ title }) => {
  const [showModal, setModal] = useState(false);
  return (
    <>
      <div className="banner-container">
        <div className="banner">
          <Row>
            <Col sm={1}>
              <div>
                <img src={infoIcon} alt="" />
              </div>
            </Col>
            <Col>
              <div className="banner-text">
                <div className="banner-title" style={{ fontSize: "14px" }}>
                 {title === CW ? Default_Text : IE_Text}
                </div>
                <p className="mt-2">
                 {title === CW ? <b>
                    For any case related query, Use the following case numbers:
                  </b>
                  :
                  <b>
                    For any case related query, Use the following case IDs and individual IDs:
                  </b>
                  }
                </p>
               {title === CW ? <div className="case-num-container mb-4">
                  <p style={{ borderRight: "1px solid" }}>a116C000001SiHPQA0</p>
                  <p>a116C000001SiHUQA0</p>     
                </div> :
                <div className="mb-4">
                {
                IE_Case_ID?.map((item, index) => {
                  return (
                    <div className="case-num-container" key={index}>
                      <p style={{ borderRight: "1px solid", width: '110px' }}>{item.caseID}</p>
                      <p style={{ width: '110px'}}>{item.individualID}</p>
                    </div>
                  );
                })}
                </div>
                  }
                <button className="view-btn" onClick={() => setModal(true)}>
                  View More
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="banner-footer">
          <Col md={{ span: 9, offset: 1 }}>
            <span>
              Note : This section will get updated with more training
              iterations.
            </span>
          </Col>
        </div>
      </div>
      {showModal && (
        <Modal
          show={showModal}
          onHide={() => setModal(false)}
          backdrop="static"
          keyboard={true}
          size="xl"
        >
          <Modal.Header closeButton style={{ backgroundColor: "#5DBD8B" }}>
            <Modal.Title>View More Details</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p className="m-0">
              <b>What type of Data being used?</b>
            </p>
            <span className="">
              A transparent breakdown on what type of data is the chatbot being
              run on
            </span>
            {title === CW ? (
              <p className="mt-4">
                <b>Policy Documents</b>
                <br /> LLM was fine-tuned on questions and answer pairs
                generated from CA CW policy docs shared with us by SMEs. These
                policy docs are from “State of California – Health and Human
                Service Agency – Department of Social Services”. Please see all
                these docs here -  CA. Generated Q&A pairs can be found here.
                RAG on top of Fine-tuned LLM leverages same CA CW policy
                documents mentioned above. This bot should be able to answer
                questions from these documents. 
              </p>
            ) : (
              <p className="mt-4">
                <b>Policy Documents</b>
                <br /> LLM was fine-tuned on questions and answer pairs
                generated from 3 sets of policy docs shared with us. These
                policy docs are from “Bridges Administrative Manual, State of
                Michigan, Department of Health & Human” and “SNAP/TANF for Code
                of Colorado Regulations, Secretary of State, State of Colorado,
                Department of Human Services”. Please see all these docs here -
                 <a href="https://amedeloitte.sharepoint.com/sites/LLMFineTuning/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FLLMFineTuning%2FShared%20Documents%2FGeneral%2F00%2E%20Domain%20Specific%20AI%2F01%2E%20Integrated%20Eligibility%2F01%2E%20Policy%20Docs&viewid=0f79a8d5%2D7009%2D4c03%2Da505%2Dfbaba8b32704" rel="noreferrer" target="_blank">CA</a>. Generated Q&A pairs can be found <a href="https://amedeloitte.sharepoint.com/sites/LLMFineTuning/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FLLMFineTuning%2FShared%20Documents%2FGeneral%2F00%2E%20Domain%20Specific%20AI%2F01%2E%20Integrated%20Eligibility%2F02%2E%20Policy%20Q%26A%20Pairs&viewid=0f79a8d5%2D7009%2D4c03%2Da505%2Dfbaba8b32704" target="_blank" rel="noreferrer">here</a>. RAG on top of
                Fine-tuned LLM leverages same IE SNAP policy documents mentioned
                above. This bot should be able to answer questions from these
                documents. 
              </p>
            )}
            {title === CW ? (
              <>
                {" "}
                <p>
                  <b>Case Data (sqllite DB) </b>
                  <br />
                  This solution leverages CCWIS Data Extract of CW asset from
                  GovConnect team. It contains 10 tables for 2 cases. It has
                  data around basic case information, court orders, petitions,
                  case notes etc. See below for high level descriptions of
                  data: 
                </p>
                <span>
                  1.Intake and Case object: Captures intake details and its
                  associated case, enabling linkage between these two entities.
                </span>
                <br />
                <span>
                  2. Case object: Records and tracks the journey of different
                  case types related to users.
                </span>
                <br />
                <span>
                  3. Court Orders object: Stores details about court orders for
                  various case types such as custody orders and adoption
                  finalization.
                </span>
                <br />
                <span>
                  4. Permanency Referral object: Handles referral information
                  for the Permanency Planning Committee (PPC) for case planning
                  and goal setting.
                </span>
                <br />
                <span>
                  5. Assessment object: Contains case assessment details,
                  driving functionalities related to investigation assessments.
                </span>
                <br />
                <span>
                  6. Petition object: Captures petition details and their
                  outcomes in court orders.
                </span>
                <br />
                <span>
                  7. Placement object: Records the details of a client's foster
                  home placements.
                </span>
                <br />
                <span>
                  8. Client Assessment object: Stores details of assessments
                  completed for a client.
                </span>
                <br />
                <span>
                  9. Person-Case Junction object: Maps the related information
                  of persons and cases.
                </span>
                <br />
                <span>
                  10. Notes object: Stores specific notes related to intake,
                  person, case, etc., and is linked to various other objects. 
                </span>
                <br />
                <p className="mt-4 mb-2">
                  Note, you can use below two case IDs to ask your questions:
                </p>
                <div
                  className="case-num-container mb-4"
                  style={{ borderColor: "black" }}
                >
                  <p style={{ borderRight: "1px solid" }}>a116C000001SiHPQA0</p>
                  <p>a116C000001SiHUQA0</p>
                </div>
              </>
            ) : (
              <>
                <p>
                  <b>Case Data (sqllite DB) </b>
                  <br />
                  This solution leverages asset from GovConnect team. It
                  contains 7 tables for 35 cases. It has data around the case
                  information, details of the application, individual details
                  etc. See below for high level descriptions of data: 
                </p>
                <span>
                  1. Individual Case Object: Records the links for all
                  individuals to a case.{" "}
                </span>
                <br />
                <span>
                  2. Appointment Object: Captures the appointment details of the
                  user.{" "}
                </span>
                <br />
                <span>
                  3. Case Program Object: Records the entire details of the
                  programs requested in the case.{" "}
                </span>
                <br />
                <span>
                  4. Application Program Object: Stores the application and
                  program link.{" "}
                </span>
                <br />
                <span>
                  5. Application for Aid Object: Captures the basic data about
                  an application for aid.{" "}
                </span>
                <br />
                <span>
                  6. Cases Object: Stores the basic case information. Used to
                  get information related to case status. Also used to get
                  information about the case mode and benefits.{" "}
                </span>
                <br />
                <span>
                  7. Individual Object: Records the individual’s basic
                  identifiable information.{" "}
                </span>
                <br />
              </>
            )}

            <hr />
            {title === CW ? (
              <>
                <p className="m-0">
                  <b>Demo Sample Script</b>
                </p>
                <span>
                  Demo sample script that can be used in given flow, to get you
                  started with your conversation
                </span>
                <p className="mt-2">
                  Below demo script can be used in given flow. It is about a
                  child who ran away from his home due to negligence of his
                  parents. A petition was filed for his temporary stay in foster
                  home, a hearing appointment was booked, and hearing conducted
                  on same day.   
                </p>
                <span>
                  1. What are the different specific court petitions available
                  in child welfare?
                </span>
                <br />
                <span>2. My case id is a116C000001SiHUQA0</span>
                <br />
                <span>
                  3. What is the current status of my petition and under which
                  category is it falling?
                </span>
                <br />
                <span>
                  4. What is DFS Dependency/Neglect Petition in child welfare?
                </span>
                <br />
                <span>5. When is the petition filed in this case?</span>
                <br />
                <span>
                  6. Ok, what is the custody type and when is the hearing for
                  this case?
                </span>
                <br />
                <span>7. Can you explain custody type "Temporary"?</span>
                <br />
                <span>
                  8. Lastly, what is the reason for placing the child in foster
                  care in this case?
                </span>
                <br />
                <span>9. Can you summarize our conversation so far?</span>
                <br />
              </>
            ) : (
              <>
                <p className="m-0">
                  <b>Demo Sample Script</b>
                </p>
                <span>
                  Demo sample script that can be used in given flow, to get you
                  started with your conversation
                </span>
                <br />
                <br />
                <span>
                  1. Can you tell me what are the recent changes in SNAP?{" "}
                </span>
                <br />
                <span>2. I want to check the status of my case </span>
                <br />
                <span>
                  3. My individual ID is 1024126370 and my case id is 122256385{" "}
                </span>
                <br />
                <span>4. Can you check if my food stamps are open?  </span>
                <br />
                <span>
                  5. When was my case last updated and who was the person to
                  update it?{" "}
                </span>
                <br />
                <span>
                  6 .What is my application number and what is my application
                  status? {" "}
                </span>
                <br />
                <span>
                  7. What are the scheduled appointment dates for my case? {" "}
                </span>
                <br />
                <span>8. I want to change my name  </span>
                <br />
                <span>9. I want to change my name to Venkatesh Kulkarni </span>
                <br />
                <span>10. Yes, I confirm this is correct </span>
                <br />
                <span>11. I want to change my email address </span>
                <br />
                <span>dummy@fake.com </span>
                <br />
                <span>dummy@deloitte.com </span>
                <br />
                <span>12. Yes, I confirm this is correct  </span>
                <br />
                <span>13. No I don't want to update anything else </span>
                <br />
                <span>
                  14. Please provide summary of changes, requested so far{" "}
                </span>
                <br />
              </>
            )}
          </Modal.Body>

          {/* <Modal.Footer>
           <Button variant="danger" onClick={clearChat}>
             Clear
           </Button>
           <Button variant="secondary" onClick={cancelClearChat}>
             Cancel
           </Button>
         </Modal.Footer> */}
        </Modal>
      )}
    </>
  );
};

export default Banner;
