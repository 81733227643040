import React from "react";
import { useState, useRef } from "react";
import "./HixComparison.css";
import {
  Container,
  Row,
  Col,
  Accordion,
  FormGroup,
  FormLabel,
  Button,
  FormSelect,
  Card,
  Badge,
  Modal,
  ModalHeader,
  Overlay,
} from "react-bootstrap";
import { Form, Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import HixCards from "../../components/HixCards/Index";
import HixFilters from "../../components/HixFilters/Index";
import { AiOutlineWechat } from "react-icons/ai";
import Chatbox from "../../components/chatBox";
import { HixJson1, HixJson2, Hix_Json3 } from "../../utils/constants";
import { json } from "stream/consumers";
import Pagination from "./pagination";

const HixComparison = () => {
  const [rating, setRating] = React.useState(0);
  const [showChatBox, setShowChatBox] = useState<boolean>(false);
  const [chatBackUp, setChatBackUp] = useState([]);
  const [selectedModel, setSelectedModel] = useState("gpt-4o");
  const [selectedValue, setSelectedValue] = useState<string>("json1");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 5;

  // const lastIndex=currentPage*recordsPerPage;
  // const firstIndex=lastIndex-recordsPerPage;
  let mapObject;
  if (selectedModel === "gpt-4o") {
    mapObject = Hix_Json3;
  }
  if (selectedModel !== "gpt-4o") {
    mapObject = HixJson1;
  }
  // const records=mapObject?.plans?.slice(firstIndex,lastIndex);
  // const no_of_plans=mapObject?.plans?.length;
  // let npage;
  // if(no_of_plans){
  //  npage=Math.ceil(no_of_plans/recordsPerPage)
  // }
  // const numbers=npage?[...Array(npage+1).keys()].slice(1):undefined
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages: number = mapObject?.plans
    ? Math.ceil(mapObject?.plans?.length / itemsPerPage)
    : 0;
  const reversedDisplayedData = mapObject?.plans?.slice();
  const displayedData = reversedDisplayedData
    ? reversedDisplayedData.slice(startIndex, endIndex)
    : 0;

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };
  const targetRef = useRef(null);
  const handleModelChange = (e: any) => {
    setSelectedModel(e);
    setCurrentPage(1);
  };
  const target = (
    <div>
      <div>
        <Modal show={showChatBox}>
          <ModalHeader></ModalHeader>
          <Modal.Body>
            <Chatbox
              title="Health Insurance Exchange"
              setSelectedModelUi={setSelectedModel}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
  return (
    <>
      <Container fluid>
        <Row className="Mock-UI-Text">
          THIS IS A MOCK UI, FOR REPRESENTATION PURPOSE ONLY
        </Row>
        <Row className="Header justify-content-center">
          <Col sm={6}>
            <h3 className="Header-Text">Choose A Health Plan</h3>
            <Link to={"/"}>
              <p className="Back-To-Preferences">{"<-"} Back To Preferences</p>
            </Link>
          </Col>
          <Col sm={6}>
            <p className="Header-Sub-Text">Expected Start Date</p>
            {/* <FormGroup style={{width:"200px",marginLeft:'75%',marginBottom:'100%'}}>
                    <FormLabel>This is a mock UI select Json Values</FormLabel>
                    <FormSelect onClick={(event:any)=>{setSelectedValue(event?.target.value)}} disabled={false}>
                        <option value={"json1"}>Json1</option>
                        <option value={"json2"}>Json2</option>
                    </FormSelect>
                </FormGroup> */}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <div className="Content-Container">
            <Col sm={3} style={{ marginRight: "20px" }}>
              <div className="Filter-Heading">Filters</div>
              <div className="hix-accordion">
                <Accordion className="Accordian" defaultActiveKey="0">
                  <Accordion.Item eventKey="0" className="Accordian">
                    <Accordion.Header>Metal Tiers</Accordion.Header>
                    <Accordion.Body>
                      {mapObject &&
                        mapObject.filterOptions.metalTiers.map((x) => {
                          return <HixFilters filterOptions={x} />;
                        })}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" className="Accordian">
                    <Accordion.Header>Insurance Companies</Accordion.Header>
                    <Accordion.Body>
                      {mapObject &&
                        mapObject.filterOptions.insuranceCompanies.map((x) => {
                          return <HixFilters filterOptions={x} />;
                        })}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="Accordian">
                    <Accordion.Header>Network Type</Accordion.Header>
                    <Accordion.Body>
                      {mapObject &&
                        mapObject.filterOptions.networkType.map((x) => {
                          return <HixFilters filterOptions={x} />;
                        })}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
            <Col sm={9} className="Plan-Right-Section">
              <Row className="Prefrences">
                <Col sm={6} className="">
                  <Button className="Prefrence-Based-Button" variant="primary">
                    <span>Prefrence Based</span>
                  </Button>
                </Col>
                <Col sm={6} className="Prefrence-Based-Heading">
                  <div>All Prefrences</div>
                </Col>
              </Row>
              <Row className="Margin-Top" style={{ marginBottom: "50px" }}>
                <Col sm={6}>
                  {selectedModel === "gpt-4o" ? (
                    <h4 style={{ marginTop: "16px" }}>
                      17 Out Of 17 Health Plans
                    </h4>
                  ) : (
                    <h4 style={{ marginTop: "16px" }}>
                      4 Out Of 4 Health Plans
                    </h4>
                  )}
                </Col>
                <Col>
                  <FormGroup className="Sort-By">
                    <FormLabel className="Sort-By-Text">Sort By</FormLabel>
                    <FormSelect className="Sort-By-Select instance-picker">
                      <option value="something" className="Select-Option">
                        <span className="Select-Option">
                          Lowest Yearly Estimated Cost
                        </span>
                      </option>
                    </FormSelect>
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ marginBottom: "50px" }}>
                <Col style={{ display: "flex" }}>
                  <div>Filters</div>
                  {mapObject &&
                    mapObject.selectedFilters.metalTiers.map((x) => {
                      return (
                        <Badge
                          bg=""
                          style={{
                            backgroundColor: "white",
                            // padding:'0',
                            color: "black",
                            marginLeft: "10px",
                            paddingTop: "6px",
                            border: "1px solid black",
                            // paddingRight: '10px' // add some padding to make room for the cross
                          }}
                        >
                          {x}
                          <span
                            style={{
                              fontSize: "12px",
                              cursor: "pointer",
                              borderRadius: "50%",
                              backgroundColor: "white",
                              color: "black",
                              padding: "2px 4px",
                              marginLeft: "10px",
                            }}
                          >
                            &times;
                          </span>
                        </Badge>
                      );
                    })}
                  {mapObject &&
                    mapObject.selectedFilters.insuranceCompanies.map((x) => {
                      return <Badge>{x}</Badge>;
                    })}
                </Col>
              </Row>
              {selectedModel === "gpt-4o"
                ? displayedData &&
                  displayedData.map((x: any) => {
                    return (
                      <HixCards
                        issuerName={x.issuer.issuerName}
                        metalTier={x.coverageLevel}
                        monthlyPremium={x.totalPlanPremium}
                        planName={x.planName}
                        networkType={x.networkType}
                        primaryCareVisitCost={x.benefits[5].value}
                        genericDrugs={x.benefits[1].value}
                        providerInNetworkCount={x.families[0].members.length}
                        planDeductible={x.planDeductible}
                        drugDeductible={
                          x.planDeductible?.drugDeductible
                            ? x.planDeductible.drugDeductible
                            : "0"
                        }
                        medicalDeductible={
                          x.planDeductible?.medicalDeductible
                            ? x.planDeductible.medicalDeductible
                            : "0"
                        }
                        estimatedCost={x.yearlyEstimatedExpense}
                        starRating={Number(
                          x.qualityRating.value[
                            x.qualityRating.value.length - 3
                          ]
                        )}
                      />
                    );
                  })
                : displayedData &&
                  displayedData.map((x: any) => {
                    return (
                      <HixCards
                        issuerName={x.issuerName}
                        metalTier={x.metalTier}
                        monthlyPremium={x.monthlyPremium}
                        planName={x.planName}
                        networkType={x.networkType}
                        primaryCareVisitCost={x.primaryCareVisitCost}
                        genericDrugs={x.genericDrugs}
                        providerInNetworkCount={x.providerInNetworkCount}
                        planDeductible={x.planDeductible}
                        estimatedCost={x.yearlyEstimatedExpense}
                        starRating={x.qualityRating}
                      />
                    );
                  })}
            </Col>
          </div>
          <Button
            className="Chat-Button"
            ref={targetRef}
            onClick={(e) => {
              setShowChatBox(!showChatBox);
            }}
          >
            <AiOutlineWechat />
          </Button>
        </Row>
        {showChatBox && (
          // <Overlay show={showChatBox} target={targetRef.current} onHide={()=>{setShowChatBox(false)}}>

          <div className="chat-pop-up">
            <Chatbox
              title="Health Insurance Exchange"
              defaultText="I am your HIX Bot. How can I assist you today for your queries related to Health Policy Plans?"
              chatBackup={chatBackUp}
              setChatBackUp={setChatBackUp}
              setSelectedModelUi={handleModelChange}
            />
          </div>

          // </Overlay>
        )}
      </Container>
      <div className="pagination">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};
export default HixComparison;
