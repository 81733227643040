import React, { useState } from 'react'
import Chatbox from '../../components/chatBox'
import { Form } from 'react-bootstrap'
import './chatScreen.css';
import { useParams } from "react-router";
import { CHATFLOWS } from "../../utils/api";
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import { Link } from 'react-router-dom';
import LogoImage from '../../assets/arrows/image.png';
import { AskMeAI, CW, IE } from '../../utils/constants';
import Banner from '../../components/Banner';
import AccordionComp from '../../components/Accordion';
import { RiShareBoxLine } from "react-icons/ri";
const ChatScreen = () => {
  const [selectedOption, setSelectedOption] = useState(true);
    const { id } = useParams();
const props = id && CHATFLOWS[id];
const AskMeText = "This tool enables uses to ask questions based on CMS Regulations, Frequently Asked Questions, Informational Bullets, and State Medicaid Directors Letters to support efficient navigation and initial understanding of this information. ​"
  return (
    <div className="application-page-container">
      {props && props.title === AskMeAI &&
      <div className='logo-style'>
          <img src={LogoImage} alt="" />
      </div>
      }
         <div className="title">{props && props.title}</div>
         {props && props.title === 'Askme.Medicaid.AI' && <div className="sub-title">{!selectedOption ? AskMeText : props.subTitle}</div>}
         {/* <div className='desc-text'>{props && props.description}</div> */}
      
        <hr className='horizontal-line'/>
      
      <div className="back-button-container">
        <Link to={"/"}>
          <BackIcon style={{filter: 'invert(1)'}} /> Back
        </Link>
        {props && props.title === 'Askme.Medicaid.AI' &&
       <div className='d-flex'>
          <span style={{color: '#fff', marginRight: '5px'}}>Document Search</span>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={selectedOption === true}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSelectedOption(
                    event.target.checked ? true : false
                  );
                
                }}
          />
          </div>
}
      </div>
      {props && (props.title === CW || props.title === IE) && 
      <>
        <Banner title={props.title}/>
        <AccordionComp title={props.title}/>
      </>
}
        <div className="single-app-container">   
        <Form.Group className="user-input" controlId="exampleForm.optionPicker">
            {
                props && 
                <Chatbox
                title={props.title}
                defaultText={props.defaultText}
                selectedOption={selectedOption}
                />
            }
        </Form.Group>
        </div>
         {props && props.title === 'Askme.Medicaid.AI' &&<div className='desc-text'>{props && props.description}</div>}
         
         <div className="frame-parent2 mt-4" style={{width: '50vw'}}>
          <div className="get-in-touch-parent">
            <div className="tailored-chat-bots" style={{fontSize: "32px"}}>Get In Touch</div>
            <div className="lorem-ipsum-dolor2">Build your own fine-tuned model today.</div>
          </div>
          <div className="deloittegpsdeloittecom-parent">
            <div className="deloittegpsdeloittecom">
              deloittegps@deloitte.com
            </div>
            <RiShareBoxLine className="external-link-icon"/>
            {/* <img
              className="external-link-icon"
              alt=""
              src="/externallink.svg"
            /> */}
          </div>
        </div>
    </div>
  )
}

export default ChatScreen