import { FunctionComponent } from "react";
import Cards from "./Cards";
import "./CardContainer.css";
import PerformanceImg from '../../../assets/Frame 1272628631.png';
import CostImg from '../../../assets/Frame 1272628632.png';
import ReliabilityImg from '../../../assets/Frame 1272628633.png';
import SafetyImg from '../../../assets/Frame 1272628634.png';
export type CardContainerType = {
  className?: string;
};

const CardContainer: FunctionComponent<CardContainerType> = ({
  className = "",
}) => {
  return (
    <div className={`frame-wrapper3 ${className}`}>
      <div className="frame-wrapper4">
        <div className="frame-parent9">
          <Cards />
          <div className="frame-parent10">
            <div className="frame-wrapper5">
              <div className="group-group">
                <div className="safety-parent">
                  <img className="safety-icon" alt="" src={CostImg} />
                  <div className="privacy">Privacy</div>
                </div>
              </div>
            </div>
            <div className="private-vpc-reduced-container">
              <p className="private-vpc">Private VPC</p>
              <p className="private-vpc">{`Reduced Data Breaches `}</p>
              <p className="zero-information">Zero Information Leakage</p>
            </div>
          </div>
          <div className="frame-parent11">
          <div className="frame-wrapper5">
              <div className="group-group">
                <div className="safety-parent">
                  <img className="safety-icon" alt="" src={ReliabilityImg} />
                  <div className="privacy">Cost</div>
                </div>
              </div>
            </div>
            <div className="reduced-costs-better-container">
              <p className="private-vpc">Reduced Costs</p>
              <p className="private-vpc">Better Transparency</p>
              <p className="private-vpc">{`Better Control `}</p>
            </div>
          </div>
          <div className="frame-parent10">
            <div className="frame-wrapper5">
              <div className="group-group">

                <div className="safety-parent">
                  <img className="group-icon3" alt="" src={SafetyImg} />
                  <div className="privacy">Reliability</div>
                </div>
              </div>
            </div>
            <div className="private-vpc-reduced-container">
              <p className="private-vpc">Lower Latency</p>
              <p className="private-vpc">Improved Moderation</p>
              <p className="private-vpc">Managed Specificity</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardContainer;
